enum ROUTES {
  MAIN = "/",
  SIGNIN = "/signin",
  SIGNUP = "/signup",
  STUDIO = "/studio",
  STUDIO_PLAYGROUND = "/studio/:id",
  SHARE = "/share/:hash",
  HUB = "/hub",
  HUB_ALL_ASSETS = "/hub/all-assets",
  HUB_ALL_ELEMENTS = "/hub/all-elements",
  HUB_BRAND_BOOK = "/hub/brand-book",
  HUB_BRAND_CALENDAR = "/hub/brand-calendar",
  ACTIVATE = "/activate",
  MAGIC_LINK = "/magic-link",
  INVITE = "/invite",
  BILLING_CREDIT_CHECKOUT = "/credit",
  BILLING_SUBSCRIPTION_CHECKOUT = "/subscription",
  TERMS_OF_USE = "https://docs.google.com/document/d/1Jx7Wx07i-Fe-mYTeGQY3HW3ZHsXnZ5idpYmDQAyuqmM/edit?usp=sharing",
  BOOK_DEMO = "/book-demo",
}

export { ROUTES };
