export const isValidEmail = (email: string): boolean => {
  // Regular expression pattern for validating email addresses
  const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the regular expression
  return emailRegex.test(email);
};

const toTitleCase = (text: string): string => {
  return text[0].toUpperCase() + text.slice(1);
};

const stringToNumericValue = (str: string): number => {
  let numericValue = 0;
  for (let i = 0; i < str.length; i++) {
    // Convert each character to its ASCII value and add it to the numeric value
    numericValue += str.charCodeAt(i);
  }
  return numericValue;
};
