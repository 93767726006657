function Ot(r, t) {
  return function() {
    return r.apply(t, arguments);
  };
}
const { toString: ne } = Object.prototype, { getPrototypeOf: lt } = Object, K = ((r) => (t) => {
  const e = ne.call(t);
  return r[e] || (r[e] = e.slice(8, -1).toLowerCase());
})(/* @__PURE__ */ Object.create(null)), R = (r) => (r = r.toLowerCase(), (t) => K(t) === r), V = (r) => (t) => typeof t === r, { isArray: D } = Array, N = V("undefined");
function re(r) {
  return r !== null && !N(r) && r.constructor !== null && !N(r.constructor) && S(r.constructor.isBuffer) && r.constructor.isBuffer(r);
}
const Ft = R("ArrayBuffer");
function se(r) {
  let t;
  return typeof ArrayBuffer < "u" && ArrayBuffer.isView ? t = ArrayBuffer.isView(r) : t = r && r.buffer && Ft(r.buffer), t;
}
const oe = V("string"), S = V("function"), jt = V("number"), G = (r) => r !== null && typeof r == "object", ae = (r) => r === !0 || r === !1, W = (r) => {
  if (K(r) !== "object")
    return !1;
  const t = lt(r);
  return (t === null || t === Object.prototype || Object.getPrototypeOf(t) === null) && !(Symbol.toStringTag in r) && !(Symbol.iterator in r);
}, ie = R("Date"), ce = R("File"), ue = R("Blob"), le = R("FileList"), he = (r) => G(r) && S(r.pipe), de = (r) => {
  let t;
  return r && (typeof FormData == "function" && r instanceof FormData || S(r.append) && ((t = K(r)) === "formdata" || // detect form-data instance
  t === "object" && S(r.toString) && r.toString() === "[object FormData]"));
}, pe = R("URLSearchParams"), [fe, ge, me, ye] = ["ReadableStream", "Request", "Response", "Headers"].map(R), ke = (r) => r.trim ? r.trim() : r.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
function $(r, t, { allOwnKeys: e = !1 } = {}) {
  if (r === null || typeof r > "u")
    return;
  let n, s;
  if (typeof r != "object" && (r = [r]), D(r))
    for (n = 0, s = r.length; n < s; n++)
      t.call(null, r[n], n, r);
  else {
    const o = e ? Object.getOwnPropertyNames(r) : Object.keys(r), a = o.length;
    let i;
    for (n = 0; n < a; n++)
      i = o[n], t.call(null, r[i], i, r);
  }
}
function Dt(r, t) {
  t = t.toLowerCase();
  const e = Object.keys(r);
  let n = e.length, s;
  for (; n-- > 0; )
    if (s = e[n], t === s.toLowerCase())
      return s;
  return null;
}
const F = (() => typeof globalThis < "u" ? globalThis : typeof self < "u" ? self : typeof window < "u" ? window : global)(), It = (r) => !N(r) && r !== F;
function rt() {
  const { caseless: r } = It(this) && this || {}, t = {}, e = (n, s) => {
    const o = r && Dt(t, s) || s;
    W(t[o]) && W(n) ? t[o] = rt(t[o], n) : W(n) ? t[o] = rt({}, n) : D(n) ? t[o] = n.slice() : t[o] = n;
  };
  for (let n = 0, s = arguments.length; n < s; n++)
    arguments[n] && $(arguments[n], e);
  return t;
}
const we = (r, t, e, { allOwnKeys: n } = {}) => ($(t, (s, o) => {
  e && S(s) ? r[o] = Ot(s, e) : r[o] = s;
}, { allOwnKeys: n }), r), _e = (r) => (r.charCodeAt(0) === 65279 && (r = r.slice(1)), r), be = (r, t, e, n) => {
  r.prototype = Object.create(t.prototype, n), r.prototype.constructor = r, Object.defineProperty(r, "super", {
    value: t.prototype
  }), e && Object.assign(r.prototype, e);
}, Te = (r, t, e, n) => {
  let s, o, a;
  const i = {};
  if (t = t || {}, r == null)
    return t;
  do {
    for (s = Object.getOwnPropertyNames(r), o = s.length; o-- > 0; )
      a = s[o], (!n || n(a, r, t)) && !i[a] && (t[a] = r[a], i[a] = !0);
    r = e !== !1 && lt(r);
  } while (r && (!e || e(r, t)) && r !== Object.prototype);
  return t;
}, Ee = (r, t, e) => {
  r = String(r), (e === void 0 || e > r.length) && (e = r.length), e -= t.length;
  const n = r.indexOf(t, e);
  return n !== -1 && n === e;
}, xe = (r) => {
  if (!r)
    return null;
  if (D(r))
    return r;
  let t = r.length;
  if (!jt(t))
    return null;
  const e = new Array(t);
  for (; t-- > 0; )
    e[t] = r[t];
  return e;
}, Ce = ((r) => (t) => r && t instanceof r)(typeof Uint8Array < "u" && lt(Uint8Array)), Se = (r, t) => {
  const n = (r && r[Symbol.iterator]).call(r);
  let s;
  for (; (s = n.next()) && !s.done; ) {
    const o = s.value;
    t.call(r, o[0], o[1]);
  }
}, Ae = (r, t) => {
  let e;
  const n = [];
  for (; (e = r.exec(t)) !== null; )
    n.push(e);
  return n;
}, Re = R("HTMLFormElement"), Ue = (r) => r.toLowerCase().replace(
  /[-_\s]([a-z\d])(\w*)/g,
  function(e, n, s) {
    return n.toUpperCase() + s;
  }
), kt = (({ hasOwnProperty: r }) => (t, e) => r.call(t, e))(Object.prototype), Pe = R("RegExp"), Lt = (r, t) => {
  const e = Object.getOwnPropertyDescriptors(r), n = {};
  $(e, (s, o) => {
    let a;
    (a = t(s, o, r)) !== !1 && (n[o] = a || s);
  }), Object.defineProperties(r, n);
}, Be = (r) => {
  Lt(r, (t, e) => {
    if (S(r) && ["arguments", "caller", "callee"].indexOf(e) !== -1)
      return !1;
    const n = r[e];
    if (S(n)) {
      if (t.enumerable = !1, "writable" in t) {
        t.writable = !1;
        return;
      }
      t.set || (t.set = () => {
        throw Error("Can not rewrite read-only method '" + e + "'");
      });
    }
  });
}, Oe = (r, t) => {
  const e = {}, n = (s) => {
    s.forEach((o) => {
      e[o] = !0;
    });
  };
  return D(r) ? n(r) : n(String(r).split(t)), e;
}, Fe = () => {
}, je = (r, t) => r != null && Number.isFinite(r = +r) ? r : t, Z = "abcdefghijklmnopqrstuvwxyz", wt = "0123456789", Nt = {
  DIGIT: wt,
  ALPHA: Z,
  ALPHA_DIGIT: Z + Z.toUpperCase() + wt
}, De = (r = 16, t = Nt.ALPHA_DIGIT) => {
  let e = "";
  const { length: n } = t;
  for (; r--; )
    e += t[Math.random() * n | 0];
  return e;
};
function Ie(r) {
  return !!(r && S(r.append) && r[Symbol.toStringTag] === "FormData" && r[Symbol.iterator]);
}
const Le = (r) => {
  const t = new Array(10), e = (n, s) => {
    if (G(n)) {
      if (t.indexOf(n) >= 0)
        return;
      if (!("toJSON" in n)) {
        t[s] = n;
        const o = D(n) ? [] : {};
        return $(n, (a, i) => {
          const d = e(a, s + 1);
          !N(d) && (o[i] = d);
        }), t[s] = void 0, o;
      }
    }
    return n;
  };
  return e(r, 0);
}, Ne = R("AsyncFunction"), $e = (r) => r && (G(r) || S(r)) && S(r.then) && S(r.catch), $t = ((r, t) => r ? setImmediate : t ? ((e, n) => (F.addEventListener("message", ({ source: s, data: o }) => {
  s === F && o === e && n.length && n.shift()();
}, !1), (s) => {
  n.push(s), F.postMessage(e, "*");
}))(`axios@${Math.random()}`, []) : (e) => setTimeout(e))(
  typeof setImmediate == "function",
  S(F.postMessage)
), Me = typeof queueMicrotask < "u" ? queueMicrotask.bind(F) : typeof process < "u" && process.nextTick || $t, c = {
  isArray: D,
  isArrayBuffer: Ft,
  isBuffer: re,
  isFormData: de,
  isArrayBufferView: se,
  isString: oe,
  isNumber: jt,
  isBoolean: ae,
  isObject: G,
  isPlainObject: W,
  isReadableStream: fe,
  isRequest: ge,
  isResponse: me,
  isHeaders: ye,
  isUndefined: N,
  isDate: ie,
  isFile: ce,
  isBlob: ue,
  isRegExp: Pe,
  isFunction: S,
  isStream: he,
  isURLSearchParams: pe,
  isTypedArray: Ce,
  isFileList: le,
  forEach: $,
  merge: rt,
  extend: we,
  trim: ke,
  stripBOM: _e,
  inherits: be,
  toFlatObject: Te,
  kindOf: K,
  kindOfTest: R,
  endsWith: Ee,
  toArray: xe,
  forEachEntry: Se,
  matchAll: Ae,
  isHTMLForm: Re,
  hasOwnProperty: kt,
  hasOwnProp: kt,
  // an alias to avoid ESLint no-prototype-builtins detection
  reduceDescriptors: Lt,
  freezeMethods: Be,
  toObjectSet: Oe,
  toCamelCase: Ue,
  noop: Fe,
  toFiniteNumber: je,
  findKey: Dt,
  global: F,
  isContextDefined: It,
  ALPHABET: Nt,
  generateString: De,
  isSpecCompliantForm: Ie,
  toJSONObject: Le,
  isAsyncFn: Ne,
  isThenable: $e,
  setImmediate: $t,
  asap: Me
};
function y(r, t, e, n, s) {
  Error.call(this), Error.captureStackTrace ? Error.captureStackTrace(this, this.constructor) : this.stack = new Error().stack, this.message = r, this.name = "AxiosError", t && (this.code = t), e && (this.config = e), n && (this.request = n), s && (this.response = s, this.status = s.status ? s.status : null);
}
c.inherits(y, Error, {
  toJSON: function() {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: this.description,
      number: this.number,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: c.toJSONObject(this.config),
      code: this.code,
      status: this.status
    };
  }
});
const Mt = y.prototype, Wt = {};
[
  "ERR_BAD_OPTION_VALUE",
  "ERR_BAD_OPTION",
  "ECONNABORTED",
  "ETIMEDOUT",
  "ERR_NETWORK",
  "ERR_FR_TOO_MANY_REDIRECTS",
  "ERR_DEPRECATED",
  "ERR_BAD_RESPONSE",
  "ERR_BAD_REQUEST",
  "ERR_CANCELED",
  "ERR_NOT_SUPPORT",
  "ERR_INVALID_URL"
  // eslint-disable-next-line func-names
].forEach((r) => {
  Wt[r] = { value: r };
});
Object.defineProperties(y, Wt);
Object.defineProperty(Mt, "isAxiosError", { value: !0 });
y.from = (r, t, e, n, s, o) => {
  const a = Object.create(Mt);
  return c.toFlatObject(r, a, function(d) {
    return d !== Error.prototype;
  }, (i) => i !== "isAxiosError"), y.call(a, r.message, t, e, n, s), a.cause = r, a.name = r.name, o && Object.assign(a, o), a;
};
const We = null;
function st(r) {
  return c.isPlainObject(r) || c.isArray(r);
}
function vt(r) {
  return c.endsWith(r, "[]") ? r.slice(0, -2) : r;
}
function _t(r, t, e) {
  return r ? r.concat(t).map(function(s, o) {
    return s = vt(s), !e && o ? "[" + s + "]" : s;
  }).join(e ? "." : "") : t;
}
function ve(r) {
  return c.isArray(r) && !r.some(st);
}
const qe = c.toFlatObject(c, {}, null, function(t) {
  return /^is[A-Z]/.test(t);
});
function X(r, t, e) {
  if (!c.isObject(r))
    throw new TypeError("target must be an object");
  t = t || new FormData(), e = c.toFlatObject(e, {
    metaTokens: !0,
    dots: !1,
    indexes: !1
  }, !1, function(m, p) {
    return !c.isUndefined(p[m]);
  });
  const n = e.metaTokens, s = e.visitor || h, o = e.dots, a = e.indexes, d = (e.Blob || typeof Blob < "u" && Blob) && c.isSpecCompliantForm(t);
  if (!c.isFunction(s))
    throw new TypeError("visitor must be a function");
  function u(g) {
    if (g === null)
      return "";
    if (c.isDate(g))
      return g.toISOString();
    if (!d && c.isBlob(g))
      throw new y("Blob is not supported. Use a Buffer instead.");
    return c.isArrayBuffer(g) || c.isTypedArray(g) ? d && typeof Blob == "function" ? new Blob([g]) : Buffer.from(g) : g;
  }
  function h(g, m, p) {
    let _ = g;
    if (g && !p && typeof g == "object") {
      if (c.endsWith(m, "{}"))
        m = n ? m : m.slice(0, -2), g = JSON.stringify(g);
      else if (c.isArray(g) && ve(g) || (c.isFileList(g) || c.endsWith(m, "[]")) && (_ = c.toArray(g)))
        return m = vt(m), _.forEach(function(E, U) {
          !(c.isUndefined(E) || E === null) && t.append(
            // eslint-disable-next-line no-nested-ternary
            a === !0 ? _t([m], U, o) : a === null ? m : m + "[]",
            u(E)
          );
        }), !1;
    }
    return st(g) ? !0 : (t.append(_t(p, m, o), u(g)), !1);
  }
  const f = [], k = Object.assign(qe, {
    defaultVisitor: h,
    convertValue: u,
    isVisitable: st
  });
  function w(g, m) {
    if (!c.isUndefined(g)) {
      if (f.indexOf(g) !== -1)
        throw Error("Circular reference detected in " + m.join("."));
      f.push(g), c.forEach(g, function(_, T) {
        (!(c.isUndefined(_) || _ === null) && s.call(
          t,
          _,
          c.isString(T) ? T.trim() : T,
          m,
          k
        )) === !0 && w(_, m ? m.concat(T) : [T]);
      }), f.pop();
    }
  }
  if (!c.isObject(r))
    throw new TypeError("data must be an object");
  return w(r), t;
}
function bt(r) {
  const t = {
    "!": "%21",
    "'": "%27",
    "(": "%28",
    ")": "%29",
    "~": "%7E",
    "%20": "+",
    "%00": "\0"
  };
  return encodeURIComponent(r).replace(/[!'()~]|%20|%00/g, function(n) {
    return t[n];
  });
}
function ht(r, t) {
  this._pairs = [], r && X(r, this, t);
}
const qt = ht.prototype;
qt.append = function(t, e) {
  this._pairs.push([t, e]);
};
qt.toString = function(t) {
  const e = t ? function(n) {
    return t.call(this, n, bt);
  } : bt;
  return this._pairs.map(function(s) {
    return e(s[0]) + "=" + e(s[1]);
  }, "").join("&");
};
function He(r) {
  return encodeURIComponent(r).replace(/%3A/gi, ":").replace(/%24/g, "$").replace(/%2C/gi, ",").replace(/%20/g, "+").replace(/%5B/gi, "[").replace(/%5D/gi, "]");
}
function Ht(r, t, e) {
  if (!t)
    return r;
  const n = e && e.encode || He, s = e && e.serialize;
  let o;
  if (s ? o = s(t, e) : o = c.isURLSearchParams(t) ? t.toString() : new ht(t, e).toString(n), o) {
    const a = r.indexOf("#");
    a !== -1 && (r = r.slice(0, a)), r += (r.indexOf("?") === -1 ? "?" : "&") + o;
  }
  return r;
}
class ze {
  constructor() {
    this.handlers = [];
  }
  /**
   * Add a new interceptor to the stack
   *
   * @param {Function} fulfilled The function to handle `then` for a `Promise`
   * @param {Function} rejected The function to handle `reject` for a `Promise`
   *
   * @return {Number} An ID used to remove interceptor later
   */
  use(t, e, n) {
    return this.handlers.push({
      fulfilled: t,
      rejected: e,
      synchronous: n ? n.synchronous : !1,
      runWhen: n ? n.runWhen : null
    }), this.handlers.length - 1;
  }
  /**
   * Remove an interceptor from the stack
   *
   * @param {Number} id The ID that was returned by `use`
   *
   * @returns {Boolean} `true` if the interceptor was removed, `false` otherwise
   */
  eject(t) {
    this.handlers[t] && (this.handlers[t] = null);
  }
  /**
   * Clear all interceptors from the stack
   *
   * @returns {void}
   */
  clear() {
    this.handlers && (this.handlers = []);
  }
  /**
   * Iterate over all the registered interceptors
   *
   * This method is particularly useful for skipping over any
   * interceptors that may have become `null` calling `eject`.
   *
   * @param {Function} fn The function to call for each interceptor
   *
   * @returns {void}
   */
  forEach(t) {
    c.forEach(this.handlers, function(n) {
      n !== null && t(n);
    });
  }
}
const Tt = ze, zt = {
  silentJSONParsing: !0,
  forcedJSONParsing: !0,
  clarifyTimeoutError: !1
}, Je = typeof URLSearchParams < "u" ? URLSearchParams : ht, Ke = typeof FormData < "u" ? FormData : null, Ve = typeof Blob < "u" ? Blob : null, Ge = {
  isBrowser: !0,
  classes: {
    URLSearchParams: Je,
    FormData: Ke,
    Blob: Ve
  },
  protocols: ["http", "https", "file", "blob", "url", "data"]
}, dt = typeof window < "u" && typeof document < "u", ot = typeof navigator == "object" && navigator || void 0, Xe = dt && (!ot || ["ReactNative", "NativeScript", "NS"].indexOf(ot.product) < 0), Qe = (() => typeof WorkerGlobalScope < "u" && // eslint-disable-next-line no-undef
self instanceof WorkerGlobalScope && typeof self.importScripts == "function")(), Ye = dt && window.location.href || "http://localhost", Ze = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  hasBrowserEnv: dt,
  hasStandardBrowserEnv: Xe,
  hasStandardBrowserWebWorkerEnv: Qe,
  navigator: ot,
  origin: Ye
}, Symbol.toStringTag, { value: "Module" })), C = {
  ...Ze,
  ...Ge
};
function tn(r, t) {
  return X(r, new C.classes.URLSearchParams(), Object.assign({
    visitor: function(e, n, s, o) {
      return C.isNode && c.isBuffer(e) ? (this.append(n, e.toString("base64")), !1) : o.defaultVisitor.apply(this, arguments);
    }
  }, t));
}
function en(r) {
  return c.matchAll(/\w+|\[(\w*)]/g, r).map((t) => t[0] === "[]" ? "" : t[1] || t[0]);
}
function nn(r) {
  const t = {}, e = Object.keys(r);
  let n;
  const s = e.length;
  let o;
  for (n = 0; n < s; n++)
    o = e[n], t[o] = r[o];
  return t;
}
function Jt(r) {
  function t(e, n, s, o) {
    let a = e[o++];
    if (a === "__proto__")
      return !0;
    const i = Number.isFinite(+a), d = o >= e.length;
    return a = !a && c.isArray(s) ? s.length : a, d ? (c.hasOwnProp(s, a) ? s[a] = [s[a], n] : s[a] = n, !i) : ((!s[a] || !c.isObject(s[a])) && (s[a] = []), t(e, n, s[a], o) && c.isArray(s[a]) && (s[a] = nn(s[a])), !i);
  }
  if (c.isFormData(r) && c.isFunction(r.entries)) {
    const e = {};
    return c.forEachEntry(r, (n, s) => {
      t(en(n), s, e, 0);
    }), e;
  }
  return null;
}
function rn(r, t, e) {
  if (c.isString(r))
    try {
      return (t || JSON.parse)(r), c.trim(r);
    } catch (n) {
      if (n.name !== "SyntaxError")
        throw n;
    }
  return (e || JSON.stringify)(r);
}
const pt = {
  transitional: zt,
  adapter: ["xhr", "http", "fetch"],
  transformRequest: [function(t, e) {
    const n = e.getContentType() || "", s = n.indexOf("application/json") > -1, o = c.isObject(t);
    if (o && c.isHTMLForm(t) && (t = new FormData(t)), c.isFormData(t))
      return s ? JSON.stringify(Jt(t)) : t;
    if (c.isArrayBuffer(t) || c.isBuffer(t) || c.isStream(t) || c.isFile(t) || c.isBlob(t) || c.isReadableStream(t))
      return t;
    if (c.isArrayBufferView(t))
      return t.buffer;
    if (c.isURLSearchParams(t))
      return e.setContentType("application/x-www-form-urlencoded;charset=utf-8", !1), t.toString();
    let i;
    if (o) {
      if (n.indexOf("application/x-www-form-urlencoded") > -1)
        return tn(t, this.formSerializer).toString();
      if ((i = c.isFileList(t)) || n.indexOf("multipart/form-data") > -1) {
        const d = this.env && this.env.FormData;
        return X(
          i ? { "files[]": t } : t,
          d && new d(),
          this.formSerializer
        );
      }
    }
    return o || s ? (e.setContentType("application/json", !1), rn(t)) : t;
  }],
  transformResponse: [function(t) {
    const e = this.transitional || pt.transitional, n = e && e.forcedJSONParsing, s = this.responseType === "json";
    if (c.isResponse(t) || c.isReadableStream(t))
      return t;
    if (t && c.isString(t) && (n && !this.responseType || s)) {
      const a = !(e && e.silentJSONParsing) && s;
      try {
        return JSON.parse(t);
      } catch (i) {
        if (a)
          throw i.name === "SyntaxError" ? y.from(i, y.ERR_BAD_RESPONSE, this, null, this.response) : i;
      }
    }
    return t;
  }],
  /**
   * A timeout in milliseconds to abort a request. If set to 0 (default) a
   * timeout is not created.
   */
  timeout: 0,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  maxContentLength: -1,
  maxBodyLength: -1,
  env: {
    FormData: C.classes.FormData,
    Blob: C.classes.Blob
  },
  validateStatus: function(t) {
    return t >= 200 && t < 300;
  },
  headers: {
    common: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": void 0
    }
  }
};
c.forEach(["delete", "get", "head", "post", "put", "patch"], (r) => {
  pt.headers[r] = {};
});
const ft = pt, sn = c.toObjectSet([
  "age",
  "authorization",
  "content-length",
  "content-type",
  "etag",
  "expires",
  "from",
  "host",
  "if-modified-since",
  "if-unmodified-since",
  "last-modified",
  "location",
  "max-forwards",
  "proxy-authorization",
  "referer",
  "retry-after",
  "user-agent"
]), on = (r) => {
  const t = {};
  let e, n, s;
  return r && r.split(`
`).forEach(function(a) {
    s = a.indexOf(":"), e = a.substring(0, s).trim().toLowerCase(), n = a.substring(s + 1).trim(), !(!e || t[e] && sn[e]) && (e === "set-cookie" ? t[e] ? t[e].push(n) : t[e] = [n] : t[e] = t[e] ? t[e] + ", " + n : n);
  }), t;
}, Et = Symbol("internals");
function L(r) {
  return r && String(r).trim().toLowerCase();
}
function v(r) {
  return r === !1 || r == null ? r : c.isArray(r) ? r.map(v) : String(r);
}
function an(r) {
  const t = /* @__PURE__ */ Object.create(null), e = /([^\s,;=]+)\s*(?:=\s*([^,;]+))?/g;
  let n;
  for (; n = e.exec(r); )
    t[n[1]] = n[2];
  return t;
}
const cn = (r) => /^[-_a-zA-Z0-9^`|~,!#$%&'*+.]+$/.test(r.trim());
function tt(r, t, e, n, s) {
  if (c.isFunction(n))
    return n.call(this, t, e);
  if (s && (t = e), !!c.isString(t)) {
    if (c.isString(n))
      return t.indexOf(n) !== -1;
    if (c.isRegExp(n))
      return n.test(t);
  }
}
function un(r) {
  return r.trim().toLowerCase().replace(/([a-z\d])(\w*)/g, (t, e, n) => e.toUpperCase() + n);
}
function ln(r, t) {
  const e = c.toCamelCase(" " + t);
  ["get", "set", "has"].forEach((n) => {
    Object.defineProperty(r, n + e, {
      value: function(s, o, a) {
        return this[n].call(this, t, s, o, a);
      },
      configurable: !0
    });
  });
}
class Q {
  constructor(t) {
    t && this.set(t);
  }
  set(t, e, n) {
    const s = this;
    function o(i, d, u) {
      const h = L(d);
      if (!h)
        throw new Error("header name must be a non-empty string");
      const f = c.findKey(s, h);
      (!f || s[f] === void 0 || u === !0 || u === void 0 && s[f] !== !1) && (s[f || d] = v(i));
    }
    const a = (i, d) => c.forEach(i, (u, h) => o(u, h, d));
    if (c.isPlainObject(t) || t instanceof this.constructor)
      a(t, e);
    else if (c.isString(t) && (t = t.trim()) && !cn(t))
      a(on(t), e);
    else if (c.isHeaders(t))
      for (const [i, d] of t.entries())
        o(d, i, n);
    else
      t != null && o(e, t, n);
    return this;
  }
  get(t, e) {
    if (t = L(t), t) {
      const n = c.findKey(this, t);
      if (n) {
        const s = this[n];
        if (!e)
          return s;
        if (e === !0)
          return an(s);
        if (c.isFunction(e))
          return e.call(this, s, n);
        if (c.isRegExp(e))
          return e.exec(s);
        throw new TypeError("parser must be boolean|regexp|function");
      }
    }
  }
  has(t, e) {
    if (t = L(t), t) {
      const n = c.findKey(this, t);
      return !!(n && this[n] !== void 0 && (!e || tt(this, this[n], n, e)));
    }
    return !1;
  }
  delete(t, e) {
    const n = this;
    let s = !1;
    function o(a) {
      if (a = L(a), a) {
        const i = c.findKey(n, a);
        i && (!e || tt(n, n[i], i, e)) && (delete n[i], s = !0);
      }
    }
    return c.isArray(t) ? t.forEach(o) : o(t), s;
  }
  clear(t) {
    const e = Object.keys(this);
    let n = e.length, s = !1;
    for (; n--; ) {
      const o = e[n];
      (!t || tt(this, this[o], o, t, !0)) && (delete this[o], s = !0);
    }
    return s;
  }
  normalize(t) {
    const e = this, n = {};
    return c.forEach(this, (s, o) => {
      const a = c.findKey(n, o);
      if (a) {
        e[a] = v(s), delete e[o];
        return;
      }
      const i = t ? un(o) : String(o).trim();
      i !== o && delete e[o], e[i] = v(s), n[i] = !0;
    }), this;
  }
  concat(...t) {
    return this.constructor.concat(this, ...t);
  }
  toJSON(t) {
    const e = /* @__PURE__ */ Object.create(null);
    return c.forEach(this, (n, s) => {
      n != null && n !== !1 && (e[s] = t && c.isArray(n) ? n.join(", ") : n);
    }), e;
  }
  [Symbol.iterator]() {
    return Object.entries(this.toJSON())[Symbol.iterator]();
  }
  toString() {
    return Object.entries(this.toJSON()).map(([t, e]) => t + ": " + e).join(`
`);
  }
  get [Symbol.toStringTag]() {
    return "AxiosHeaders";
  }
  static from(t) {
    return t instanceof this ? t : new this(t);
  }
  static concat(t, ...e) {
    const n = new this(t);
    return e.forEach((s) => n.set(s)), n;
  }
  static accessor(t) {
    const n = (this[Et] = this[Et] = {
      accessors: {}
    }).accessors, s = this.prototype;
    function o(a) {
      const i = L(a);
      n[i] || (ln(s, a), n[i] = !0);
    }
    return c.isArray(t) ? t.forEach(o) : o(t), this;
  }
}
Q.accessor(["Content-Type", "Content-Length", "Accept", "Accept-Encoding", "User-Agent", "Authorization"]);
c.reduceDescriptors(Q.prototype, ({ value: r }, t) => {
  let e = t[0].toUpperCase() + t.slice(1);
  return {
    get: () => r,
    set(n) {
      this[e] = n;
    }
  };
});
c.freezeMethods(Q);
const A = Q;
function et(r, t) {
  const e = this || ft, n = t || e, s = A.from(n.headers);
  let o = n.data;
  return c.forEach(r, function(i) {
    o = i.call(e, o, s.normalize(), t ? t.status : void 0);
  }), s.normalize(), o;
}
function Kt(r) {
  return !!(r && r.__CANCEL__);
}
function I(r, t, e) {
  y.call(this, r ?? "canceled", y.ERR_CANCELED, t, e), this.name = "CanceledError";
}
c.inherits(I, y, {
  __CANCEL__: !0
});
function Vt(r, t, e) {
  const n = e.config.validateStatus;
  !e.status || !n || n(e.status) ? r(e) : t(new y(
    "Request failed with status code " + e.status,
    [y.ERR_BAD_REQUEST, y.ERR_BAD_RESPONSE][Math.floor(e.status / 100) - 4],
    e.config,
    e.request,
    e
  ));
}
function hn(r) {
  const t = /^([-+\w]{1,25})(:?\/\/|:)/.exec(r);
  return t && t[1] || "";
}
function dn(r, t) {
  r = r || 10;
  const e = new Array(r), n = new Array(r);
  let s = 0, o = 0, a;
  return t = t !== void 0 ? t : 1e3, function(d) {
    const u = Date.now(), h = n[o];
    a || (a = u), e[s] = d, n[s] = u;
    let f = o, k = 0;
    for (; f !== s; )
      k += e[f++], f = f % r;
    if (s = (s + 1) % r, s === o && (o = (o + 1) % r), u - a < t)
      return;
    const w = h && u - h;
    return w ? Math.round(k * 1e3 / w) : void 0;
  };
}
function pn(r, t) {
  let e = 0, n = 1e3 / t, s, o;
  const a = (u, h = Date.now()) => {
    e = h, s = null, o && (clearTimeout(o), o = null), r.apply(null, u);
  };
  return [(...u) => {
    const h = Date.now(), f = h - e;
    f >= n ? a(u, h) : (s = u, o || (o = setTimeout(() => {
      o = null, a(s);
    }, n - f)));
  }, () => s && a(s)];
}
const H = (r, t, e = 3) => {
  let n = 0;
  const s = dn(50, 250);
  return pn((o) => {
    const a = o.loaded, i = o.lengthComputable ? o.total : void 0, d = a - n, u = s(d), h = a <= i;
    n = a;
    const f = {
      loaded: a,
      total: i,
      progress: i ? a / i : void 0,
      bytes: d,
      rate: u || void 0,
      estimated: u && i && h ? (i - a) / u : void 0,
      event: o,
      lengthComputable: i != null,
      [t ? "download" : "upload"]: !0
    };
    r(f);
  }, e);
}, xt = (r, t) => {
  const e = r != null;
  return [(n) => t[0]({
    lengthComputable: e,
    total: r,
    loaded: n
  }), t[1]];
}, Ct = (r) => (...t) => c.asap(() => r(...t)), fn = C.hasStandardBrowserEnv ? (
  // Standard browser envs have full support of the APIs needed to test
  // whether the request URL is of the same origin as current location.
  function() {
    const t = C.navigator && /(msie|trident)/i.test(C.navigator.userAgent), e = document.createElement("a");
    let n;
    function s(o) {
      let a = o;
      return t && (e.setAttribute("href", a), a = e.href), e.setAttribute("href", a), {
        href: e.href,
        protocol: e.protocol ? e.protocol.replace(/:$/, "") : "",
        host: e.host,
        search: e.search ? e.search.replace(/^\?/, "") : "",
        hash: e.hash ? e.hash.replace(/^#/, "") : "",
        hostname: e.hostname,
        port: e.port,
        pathname: e.pathname.charAt(0) === "/" ? e.pathname : "/" + e.pathname
      };
    }
    return n = s(window.location.href), function(a) {
      const i = c.isString(a) ? s(a) : a;
      return i.protocol === n.protocol && i.host === n.host;
    };
  }()
) : (
  // Non standard browser envs (web workers, react-native) lack needed support.
  function() {
    return function() {
      return !0;
    };
  }()
), gn = C.hasStandardBrowserEnv ? (
  // Standard browser envs support document.cookie
  {
    write(r, t, e, n, s, o) {
      const a = [r + "=" + encodeURIComponent(t)];
      c.isNumber(e) && a.push("expires=" + new Date(e).toGMTString()), c.isString(n) && a.push("path=" + n), c.isString(s) && a.push("domain=" + s), o === !0 && a.push("secure"), document.cookie = a.join("; ");
    },
    read(r) {
      const t = document.cookie.match(new RegExp("(^|;\\s*)(" + r + ")=([^;]*)"));
      return t ? decodeURIComponent(t[3]) : null;
    },
    remove(r) {
      this.write(r, "", Date.now() - 864e5);
    }
  }
) : (
  // Non-standard browser env (web workers, react-native) lack needed support.
  {
    write() {
    },
    read() {
      return null;
    },
    remove() {
    }
  }
);
function mn(r) {
  return /^([a-z][a-z\d+\-.]*:)?\/\//i.test(r);
}
function yn(r, t) {
  return t ? r.replace(/\/?\/$/, "") + "/" + t.replace(/^\/+/, "") : r;
}
function Gt(r, t) {
  return r && !mn(t) ? yn(r, t) : t;
}
const St = (r) => r instanceof A ? { ...r } : r;
function j(r, t) {
  t = t || {};
  const e = {};
  function n(u, h, f) {
    return c.isPlainObject(u) && c.isPlainObject(h) ? c.merge.call({ caseless: f }, u, h) : c.isPlainObject(h) ? c.merge({}, h) : c.isArray(h) ? h.slice() : h;
  }
  function s(u, h, f) {
    if (c.isUndefined(h)) {
      if (!c.isUndefined(u))
        return n(void 0, u, f);
    } else
      return n(u, h, f);
  }
  function o(u, h) {
    if (!c.isUndefined(h))
      return n(void 0, h);
  }
  function a(u, h) {
    if (c.isUndefined(h)) {
      if (!c.isUndefined(u))
        return n(void 0, u);
    } else
      return n(void 0, h);
  }
  function i(u, h, f) {
    if (f in t)
      return n(u, h);
    if (f in r)
      return n(void 0, u);
  }
  const d = {
    url: o,
    method: o,
    data: o,
    baseURL: a,
    transformRequest: a,
    transformResponse: a,
    paramsSerializer: a,
    timeout: a,
    timeoutMessage: a,
    withCredentials: a,
    withXSRFToken: a,
    adapter: a,
    responseType: a,
    xsrfCookieName: a,
    xsrfHeaderName: a,
    onUploadProgress: a,
    onDownloadProgress: a,
    decompress: a,
    maxContentLength: a,
    maxBodyLength: a,
    beforeRedirect: a,
    transport: a,
    httpAgent: a,
    httpsAgent: a,
    cancelToken: a,
    socketPath: a,
    responseEncoding: a,
    validateStatus: i,
    headers: (u, h) => s(St(u), St(h), !0)
  };
  return c.forEach(Object.keys(Object.assign({}, r, t)), function(h) {
    const f = d[h] || s, k = f(r[h], t[h], h);
    c.isUndefined(k) && f !== i || (e[h] = k);
  }), e;
}
const Xt = (r) => {
  const t = j({}, r);
  let { data: e, withXSRFToken: n, xsrfHeaderName: s, xsrfCookieName: o, headers: a, auth: i } = t;
  t.headers = a = A.from(a), t.url = Ht(Gt(t.baseURL, t.url), r.params, r.paramsSerializer), i && a.set(
    "Authorization",
    "Basic " + btoa((i.username || "") + ":" + (i.password ? unescape(encodeURIComponent(i.password)) : ""))
  );
  let d;
  if (c.isFormData(e)) {
    if (C.hasStandardBrowserEnv || C.hasStandardBrowserWebWorkerEnv)
      a.setContentType(void 0);
    else if ((d = a.getContentType()) !== !1) {
      const [u, ...h] = d ? d.split(";").map((f) => f.trim()).filter(Boolean) : [];
      a.setContentType([u || "multipart/form-data", ...h].join("; "));
    }
  }
  if (C.hasStandardBrowserEnv && (n && c.isFunction(n) && (n = n(t)), n || n !== !1 && fn(t.url))) {
    const u = s && o && gn.read(o);
    u && a.set(s, u);
  }
  return t;
}, kn = typeof XMLHttpRequest < "u", wn = kn && function(r) {
  return new Promise(function(e, n) {
    const s = Xt(r);
    let o = s.data;
    const a = A.from(s.headers).normalize();
    let { responseType: i, onUploadProgress: d, onDownloadProgress: u } = s, h, f, k, w, g;
    function m() {
      w && w(), g && g(), s.cancelToken && s.cancelToken.unsubscribe(h), s.signal && s.signal.removeEventListener("abort", h);
    }
    let p = new XMLHttpRequest();
    p.open(s.method.toUpperCase(), s.url, !0), p.timeout = s.timeout;
    function _() {
      if (!p)
        return;
      const E = A.from(
        "getAllResponseHeaders" in p && p.getAllResponseHeaders()
      ), x = {
        data: !i || i === "text" || i === "json" ? p.responseText : p.response,
        status: p.status,
        statusText: p.statusText,
        headers: E,
        config: r,
        request: p
      };
      Vt(function(O) {
        e(O), m();
      }, function(O) {
        n(O), m();
      }, x), p = null;
    }
    "onloadend" in p ? p.onloadend = _ : p.onreadystatechange = function() {
      !p || p.readyState !== 4 || p.status === 0 && !(p.responseURL && p.responseURL.indexOf("file:") === 0) || setTimeout(_);
    }, p.onabort = function() {
      p && (n(new y("Request aborted", y.ECONNABORTED, r, p)), p = null);
    }, p.onerror = function() {
      n(new y("Network Error", y.ERR_NETWORK, r, p)), p = null;
    }, p.ontimeout = function() {
      let U = s.timeout ? "timeout of " + s.timeout + "ms exceeded" : "timeout exceeded";
      const x = s.transitional || zt;
      s.timeoutErrorMessage && (U = s.timeoutErrorMessage), n(new y(
        U,
        x.clarifyTimeoutError ? y.ETIMEDOUT : y.ECONNABORTED,
        r,
        p
      )), p = null;
    }, o === void 0 && a.setContentType(null), "setRequestHeader" in p && c.forEach(a.toJSON(), function(U, x) {
      p.setRequestHeader(x, U);
    }), c.isUndefined(s.withCredentials) || (p.withCredentials = !!s.withCredentials), i && i !== "json" && (p.responseType = s.responseType), u && ([k, g] = H(u, !0), p.addEventListener("progress", k)), d && p.upload && ([f, w] = H(d), p.upload.addEventListener("progress", f), p.upload.addEventListener("loadend", w)), (s.cancelToken || s.signal) && (h = (E) => {
      p && (n(!E || E.type ? new I(null, r, p) : E), p.abort(), p = null);
    }, s.cancelToken && s.cancelToken.subscribe(h), s.signal && (s.signal.aborted ? h() : s.signal.addEventListener("abort", h)));
    const T = hn(s.url);
    if (T && C.protocols.indexOf(T) === -1) {
      n(new y("Unsupported protocol " + T + ":", y.ERR_BAD_REQUEST, r));
      return;
    }
    p.send(o || null);
  });
}, _n = (r, t) => {
  const { length: e } = r = r ? r.filter(Boolean) : [];
  if (t || e) {
    let n = new AbortController(), s;
    const o = function(u) {
      if (!s) {
        s = !0, i();
        const h = u instanceof Error ? u : this.reason;
        n.abort(h instanceof y ? h : new I(h instanceof Error ? h.message : h));
      }
    };
    let a = t && setTimeout(() => {
      a = null, o(new y(`timeout ${t} of ms exceeded`, y.ETIMEDOUT));
    }, t);
    const i = () => {
      r && (a && clearTimeout(a), a = null, r.forEach((u) => {
        u.unsubscribe ? u.unsubscribe(o) : u.removeEventListener("abort", o);
      }), r = null);
    };
    r.forEach((u) => u.addEventListener("abort", o));
    const { signal: d } = n;
    return d.unsubscribe = () => c.asap(i), d;
  }
}, bn = _n, Tn = function* (r, t) {
  let e = r.byteLength;
  if (!t || e < t) {
    yield r;
    return;
  }
  let n = 0, s;
  for (; n < e; )
    s = n + t, yield r.slice(n, s), n = s;
}, En = async function* (r, t) {
  for await (const e of xn(r))
    yield* Tn(e, t);
}, xn = async function* (r) {
  if (r[Symbol.asyncIterator]) {
    yield* r;
    return;
  }
  const t = r.getReader();
  try {
    for (; ; ) {
      const { done: e, value: n } = await t.read();
      if (e)
        break;
      yield n;
    }
  } finally {
    await t.cancel();
  }
}, At = (r, t, e, n) => {
  const s = En(r, t);
  let o = 0, a, i = (d) => {
    a || (a = !0, n && n(d));
  };
  return new ReadableStream({
    async pull(d) {
      try {
        const { done: u, value: h } = await s.next();
        if (u) {
          i(), d.close();
          return;
        }
        let f = h.byteLength;
        if (e) {
          let k = o += f;
          e(k);
        }
        d.enqueue(new Uint8Array(h));
      } catch (u) {
        throw i(u), u;
      }
    },
    cancel(d) {
      return i(d), s.return();
    }
  }, {
    highWaterMark: 2
  });
}, Y = typeof fetch == "function" && typeof Request == "function" && typeof Response == "function", Qt = Y && typeof ReadableStream == "function", Cn = Y && (typeof TextEncoder == "function" ? ((r) => (t) => r.encode(t))(new TextEncoder()) : async (r) => new Uint8Array(await new Response(r).arrayBuffer())), Yt = (r, ...t) => {
  try {
    return !!r(...t);
  } catch {
    return !1;
  }
}, Sn = Qt && Yt(() => {
  let r = !1;
  const t = new Request(C.origin, {
    body: new ReadableStream(),
    method: "POST",
    get duplex() {
      return r = !0, "half";
    }
  }).headers.has("Content-Type");
  return r && !t;
}), Rt = 64 * 1024, at = Qt && Yt(() => c.isReadableStream(new Response("").body)), z = {
  stream: at && ((r) => r.body)
};
Y && ((r) => {
  ["text", "arrayBuffer", "blob", "formData", "stream"].forEach((t) => {
    !z[t] && (z[t] = c.isFunction(r[t]) ? (e) => e[t]() : (e, n) => {
      throw new y(`Response type '${t}' is not supported`, y.ERR_NOT_SUPPORT, n);
    });
  });
})(new Response());
const An = async (r) => {
  if (r == null)
    return 0;
  if (c.isBlob(r))
    return r.size;
  if (c.isSpecCompliantForm(r))
    return (await new Request(C.origin, {
      method: "POST",
      body: r
    }).arrayBuffer()).byteLength;
  if (c.isArrayBufferView(r) || c.isArrayBuffer(r))
    return r.byteLength;
  if (c.isURLSearchParams(r) && (r = r + ""), c.isString(r))
    return (await Cn(r)).byteLength;
}, Rn = async (r, t) => {
  const e = c.toFiniteNumber(r.getContentLength());
  return e ?? An(t);
}, Un = Y && (async (r) => {
  let {
    url: t,
    method: e,
    data: n,
    signal: s,
    cancelToken: o,
    timeout: a,
    onDownloadProgress: i,
    onUploadProgress: d,
    responseType: u,
    headers: h,
    withCredentials: f = "same-origin",
    fetchOptions: k
  } = Xt(r);
  u = u ? (u + "").toLowerCase() : "text";
  let w = bn([s, o && o.toAbortSignal()], a), g;
  const m = w && w.unsubscribe && (() => {
    w.unsubscribe();
  });
  let p;
  try {
    if (d && Sn && e !== "get" && e !== "head" && (p = await Rn(h, n)) !== 0) {
      let x = new Request(t, {
        method: "POST",
        body: n,
        duplex: "half"
      }), P;
      if (c.isFormData(n) && (P = x.headers.get("content-type")) && h.setContentType(P), x.body) {
        const [O, M] = xt(
          p,
          H(Ct(d))
        );
        n = At(x.body, Rt, O, M);
      }
    }
    c.isString(f) || (f = f ? "include" : "omit");
    const _ = "credentials" in Request.prototype;
    g = new Request(t, {
      ...k,
      signal: w,
      method: e.toUpperCase(),
      headers: h.normalize().toJSON(),
      body: n,
      duplex: "half",
      credentials: _ ? f : void 0
    });
    let T = await fetch(g);
    const E = at && (u === "stream" || u === "response");
    if (at && (i || E && m)) {
      const x = {};
      ["status", "statusText", "headers"].forEach((yt) => {
        x[yt] = T[yt];
      });
      const P = c.toFiniteNumber(T.headers.get("content-length")), [O, M] = i && xt(
        P,
        H(Ct(i), !0)
      ) || [];
      T = new Response(
        At(T.body, Rt, O, () => {
          M && M(), m && m();
        }),
        x
      );
    }
    u = u || "text";
    let U = await z[c.findKey(z, u) || "text"](T, r);
    return !E && m && m(), await new Promise((x, P) => {
      Vt(x, P, {
        data: U,
        headers: A.from(T.headers),
        status: T.status,
        statusText: T.statusText,
        config: r,
        request: g
      });
    });
  } catch (_) {
    throw m && m(), _ && _.name === "TypeError" && /fetch/i.test(_.message) ? Object.assign(
      new y("Network Error", y.ERR_NETWORK, r, g),
      {
        cause: _.cause || _
      }
    ) : y.from(_, _ && _.code, r, g);
  }
}), it = {
  http: We,
  xhr: wn,
  fetch: Un
};
c.forEach(it, (r, t) => {
  if (r) {
    try {
      Object.defineProperty(r, "name", { value: t });
    } catch {
    }
    Object.defineProperty(r, "adapterName", { value: t });
  }
});
const Ut = (r) => `- ${r}`, Pn = (r) => c.isFunction(r) || r === null || r === !1, Zt = {
  getAdapter: (r) => {
    r = c.isArray(r) ? r : [r];
    const { length: t } = r;
    let e, n;
    const s = {};
    for (let o = 0; o < t; o++) {
      e = r[o];
      let a;
      if (n = e, !Pn(e) && (n = it[(a = String(e)).toLowerCase()], n === void 0))
        throw new y(`Unknown adapter '${a}'`);
      if (n)
        break;
      s[a || "#" + o] = n;
    }
    if (!n) {
      const o = Object.entries(s).map(
        ([i, d]) => `adapter ${i} ` + (d === !1 ? "is not supported by the environment" : "is not available in the build")
      );
      let a = t ? o.length > 1 ? `since :
` + o.map(Ut).join(`
`) : " " + Ut(o[0]) : "as no adapter specified";
      throw new y(
        "There is no suitable adapter to dispatch the request " + a,
        "ERR_NOT_SUPPORT"
      );
    }
    return n;
  },
  adapters: it
};
function nt(r) {
  if (r.cancelToken && r.cancelToken.throwIfRequested(), r.signal && r.signal.aborted)
    throw new I(null, r);
}
function Pt(r) {
  return nt(r), r.headers = A.from(r.headers), r.data = et.call(
    r,
    r.transformRequest
  ), ["post", "put", "patch"].indexOf(r.method) !== -1 && r.headers.setContentType("application/x-www-form-urlencoded", !1), Zt.getAdapter(r.adapter || ft.adapter)(r).then(function(n) {
    return nt(r), n.data = et.call(
      r,
      r.transformResponse,
      n
    ), n.headers = A.from(n.headers), n;
  }, function(n) {
    return Kt(n) || (nt(r), n && n.response && (n.response.data = et.call(
      r,
      r.transformResponse,
      n.response
    ), n.response.headers = A.from(n.response.headers))), Promise.reject(n);
  });
}
const te = "1.7.7", gt = {};
["object", "boolean", "number", "function", "string", "symbol"].forEach((r, t) => {
  gt[r] = function(n) {
    return typeof n === r || "a" + (t < 1 ? "n " : " ") + r;
  };
});
const Bt = {};
gt.transitional = function(t, e, n) {
  function s(o, a) {
    return "[Axios v" + te + "] Transitional option '" + o + "'" + a + (n ? ". " + n : "");
  }
  return (o, a, i) => {
    if (t === !1)
      throw new y(
        s(a, " has been removed" + (e ? " in " + e : "")),
        y.ERR_DEPRECATED
      );
    return e && !Bt[a] && (Bt[a] = !0, console.warn(
      s(
        a,
        " has been deprecated since v" + e + " and will be removed in the near future"
      )
    )), t ? t(o, a, i) : !0;
  };
};
function Bn(r, t, e) {
  if (typeof r != "object")
    throw new y("options must be an object", y.ERR_BAD_OPTION_VALUE);
  const n = Object.keys(r);
  let s = n.length;
  for (; s-- > 0; ) {
    const o = n[s], a = t[o];
    if (a) {
      const i = r[o], d = i === void 0 || a(i, o, r);
      if (d !== !0)
        throw new y("option " + o + " must be " + d, y.ERR_BAD_OPTION_VALUE);
      continue;
    }
    if (e !== !0)
      throw new y("Unknown option " + o, y.ERR_BAD_OPTION);
  }
}
const ct = {
  assertOptions: Bn,
  validators: gt
}, B = ct.validators;
class J {
  constructor(t) {
    this.defaults = t, this.interceptors = {
      request: new Tt(),
      response: new Tt()
    };
  }
  /**
   * Dispatch a request
   *
   * @param {String|Object} configOrUrl The config specific for this request (merged with this.defaults)
   * @param {?Object} config
   *
   * @returns {Promise} The Promise to be fulfilled
   */
  async request(t, e) {
    try {
      return await this._request(t, e);
    } catch (n) {
      if (n instanceof Error) {
        let s;
        Error.captureStackTrace ? Error.captureStackTrace(s = {}) : s = new Error();
        const o = s.stack ? s.stack.replace(/^.+\n/, "") : "";
        try {
          n.stack ? o && !String(n.stack).endsWith(o.replace(/^.+\n.+\n/, "")) && (n.stack += `
` + o) : n.stack = o;
        } catch {
        }
      }
      throw n;
    }
  }
  _request(t, e) {
    typeof t == "string" ? (e = e || {}, e.url = t) : e = t || {}, e = j(this.defaults, e);
    const { transitional: n, paramsSerializer: s, headers: o } = e;
    n !== void 0 && ct.assertOptions(n, {
      silentJSONParsing: B.transitional(B.boolean),
      forcedJSONParsing: B.transitional(B.boolean),
      clarifyTimeoutError: B.transitional(B.boolean)
    }, !1), s != null && (c.isFunction(s) ? e.paramsSerializer = {
      serialize: s
    } : ct.assertOptions(s, {
      encode: B.function,
      serialize: B.function
    }, !0)), e.method = (e.method || this.defaults.method || "get").toLowerCase();
    let a = o && c.merge(
      o.common,
      o[e.method]
    );
    o && c.forEach(
      ["delete", "get", "head", "post", "put", "patch", "common"],
      (g) => {
        delete o[g];
      }
    ), e.headers = A.concat(a, o);
    const i = [];
    let d = !0;
    this.interceptors.request.forEach(function(m) {
      typeof m.runWhen == "function" && m.runWhen(e) === !1 || (d = d && m.synchronous, i.unshift(m.fulfilled, m.rejected));
    });
    const u = [];
    this.interceptors.response.forEach(function(m) {
      u.push(m.fulfilled, m.rejected);
    });
    let h, f = 0, k;
    if (!d) {
      const g = [Pt.bind(this), void 0];
      for (g.unshift.apply(g, i), g.push.apply(g, u), k = g.length, h = Promise.resolve(e); f < k; )
        h = h.then(g[f++], g[f++]);
      return h;
    }
    k = i.length;
    let w = e;
    for (f = 0; f < k; ) {
      const g = i[f++], m = i[f++];
      try {
        w = g(w);
      } catch (p) {
        m.call(this, p);
        break;
      }
    }
    try {
      h = Pt.call(this, w);
    } catch (g) {
      return Promise.reject(g);
    }
    for (f = 0, k = u.length; f < k; )
      h = h.then(u[f++], u[f++]);
    return h;
  }
  getUri(t) {
    t = j(this.defaults, t);
    const e = Gt(t.baseURL, t.url);
    return Ht(e, t.params, t.paramsSerializer);
  }
}
c.forEach(["delete", "get", "head", "options"], function(t) {
  J.prototype[t] = function(e, n) {
    return this.request(j(n || {}, {
      method: t,
      url: e,
      data: (n || {}).data
    }));
  };
});
c.forEach(["post", "put", "patch"], function(t) {
  function e(n) {
    return function(o, a, i) {
      return this.request(j(i || {}, {
        method: t,
        headers: n ? {
          "Content-Type": "multipart/form-data"
        } : {},
        url: o,
        data: a
      }));
    };
  }
  J.prototype[t] = e(), J.prototype[t + "Form"] = e(!0);
});
const q = J;
class mt {
  constructor(t) {
    if (typeof t != "function")
      throw new TypeError("executor must be a function.");
    let e;
    this.promise = new Promise(function(o) {
      e = o;
    });
    const n = this;
    this.promise.then((s) => {
      if (!n._listeners)
        return;
      let o = n._listeners.length;
      for (; o-- > 0; )
        n._listeners[o](s);
      n._listeners = null;
    }), this.promise.then = (s) => {
      let o;
      const a = new Promise((i) => {
        n.subscribe(i), o = i;
      }).then(s);
      return a.cancel = function() {
        n.unsubscribe(o);
      }, a;
    }, t(function(o, a, i) {
      n.reason || (n.reason = new I(o, a, i), e(n.reason));
    });
  }
  /**
   * Throws a `CanceledError` if cancellation has been requested.
   */
  throwIfRequested() {
    if (this.reason)
      throw this.reason;
  }
  /**
   * Subscribe to the cancel signal
   */
  subscribe(t) {
    if (this.reason) {
      t(this.reason);
      return;
    }
    this._listeners ? this._listeners.push(t) : this._listeners = [t];
  }
  /**
   * Unsubscribe from the cancel signal
   */
  unsubscribe(t) {
    if (!this._listeners)
      return;
    const e = this._listeners.indexOf(t);
    e !== -1 && this._listeners.splice(e, 1);
  }
  toAbortSignal() {
    const t = new AbortController(), e = (n) => {
      t.abort(n);
    };
    return this.subscribe(e), t.signal.unsubscribe = () => this.unsubscribe(e), t.signal;
  }
  /**
   * Returns an object that contains a new `CancelToken` and a function that, when called,
   * cancels the `CancelToken`.
   */
  static source() {
    let t;
    return {
      token: new mt(function(s) {
        t = s;
      }),
      cancel: t
    };
  }
}
const On = mt;
function Fn(r) {
  return function(e) {
    return r.apply(null, e);
  };
}
function jn(r) {
  return c.isObject(r) && r.isAxiosError === !0;
}
const ut = {
  Continue: 100,
  SwitchingProtocols: 101,
  Processing: 102,
  EarlyHints: 103,
  Ok: 200,
  Created: 201,
  Accepted: 202,
  NonAuthoritativeInformation: 203,
  NoContent: 204,
  ResetContent: 205,
  PartialContent: 206,
  MultiStatus: 207,
  AlreadyReported: 208,
  ImUsed: 226,
  MultipleChoices: 300,
  MovedPermanently: 301,
  Found: 302,
  SeeOther: 303,
  NotModified: 304,
  UseProxy: 305,
  Unused: 306,
  TemporaryRedirect: 307,
  PermanentRedirect: 308,
  BadRequest: 400,
  Unauthorized: 401,
  PaymentRequired: 402,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  NotAcceptable: 406,
  ProxyAuthenticationRequired: 407,
  RequestTimeout: 408,
  Conflict: 409,
  Gone: 410,
  LengthRequired: 411,
  PreconditionFailed: 412,
  PayloadTooLarge: 413,
  UriTooLong: 414,
  UnsupportedMediaType: 415,
  RangeNotSatisfiable: 416,
  ExpectationFailed: 417,
  ImATeapot: 418,
  MisdirectedRequest: 421,
  UnprocessableEntity: 422,
  Locked: 423,
  FailedDependency: 424,
  TooEarly: 425,
  UpgradeRequired: 426,
  PreconditionRequired: 428,
  TooManyRequests: 429,
  RequestHeaderFieldsTooLarge: 431,
  UnavailableForLegalReasons: 451,
  InternalServerError: 500,
  NotImplemented: 501,
  BadGateway: 502,
  ServiceUnavailable: 503,
  GatewayTimeout: 504,
  HttpVersionNotSupported: 505,
  VariantAlsoNegotiates: 506,
  InsufficientStorage: 507,
  LoopDetected: 508,
  NotExtended: 510,
  NetworkAuthenticationRequired: 511
};
Object.entries(ut).forEach(([r, t]) => {
  ut[t] = r;
});
const Dn = ut;
function ee(r) {
  const t = new q(r), e = Ot(q.prototype.request, t);
  return c.extend(e, q.prototype, t, { allOwnKeys: !0 }), c.extend(e, t, null, { allOwnKeys: !0 }), e.create = function(s) {
    return ee(j(r, s));
  }, e;
}
const b = ee(ft);
b.Axios = q;
b.CanceledError = I;
b.CancelToken = On;
b.isCancel = Kt;
b.VERSION = te;
b.toFormData = X;
b.AxiosError = y;
b.Cancel = b.CanceledError;
b.all = function(t) {
  return Promise.all(t);
};
b.spread = Fn;
b.isAxiosError = jn;
b.mergeConfig = j;
b.AxiosHeaders = A;
b.formToJSON = (r) => Jt(c.isHTMLForm(r) ? new FormData(r) : r);
b.getAdapter = Zt.getAdapter;
b.HttpStatusCode = Dn;
b.default = b;
const l = b;
var In = /* @__PURE__ */ ((r) => (r.THREED_MODEL = "3d-model", r.ANALOG_FILM = "analog-film", r.ANIME = "anime", r.CINEMATIC = "cinematic", r.COMIC_BOOK = "comic-book", r.DIGITAL_ART = "digital-art", r.ENHANCE = "enhance", r.FANTASY_ART = "fantasy-art", r.ISOMETRIC = "isometric", r.LINE_ART = "line-art", r.LOW_POLY = "low-poly", r.MODELING_COMPOUND = "modeling-compound", r.NEON_PUNK = "neon-punk", r.ORIGAMI = "origami", r.PHOTOGRAPHIC = "photographic", r.PIXEL_ART = "pixel-art", r.TILE_TEXTURE = "tile-texture", r))(In || {}), Ln = /* @__PURE__ */ ((r) => (r.ASPECT_RATIO_16_9 = "16:9", r.ASPECT_RATIO_1_1 = "1:1", r.ASPECT_RATIO_21_9 = "21:9", r.ASPECT_RATIO_2_3 = "2:3", r.ASPECT_RATIO_3_2 = "3:2", r.ASPECT_RATIO_4_5 = "4:5", r.ASPECT_RATIO_5_4 = "5:4", r.ASPECT_RATIO_9_16 = "9:16", r.ASPECT_RATIO_9_21 = "9:21", r))(Ln || {});
function Nn(r, t) {
  const e = atob(r), n = new Array(e.length);
  for (let o = 0; o < e.length; o++)
    n[o] = e.charCodeAt(o);
  const s = new Uint8Array(n);
  return new Blob([s], { type: t });
}
function $n(r, t) {
  return new File([r], t, { type: r.type });
}
class Mn {
  token = "null";
  working = !1;
  indexUrl = "https://index-py.hedwigai.com";
  async isWorking() {
    return this.working == !0;
  }
  async post(t, e, n = new FormData(), s = {}) {
    this.working = !0;
    const o = `${t}${e}`;
    return this.token != "null" && n.append("token", this.token), l.post(o, n, {
      timeout: 60 * 60 * 1e3,
      headers: s
    }).then((a) => {
      if (this.working = !1, a.status == 200)
        return a.data;
      throw new Error(a.statusText);
    }).catch((a) => {
      throw this.working = !1, new Error(a);
    });
  }
  async signIn(t, e) {
    const n = `${this.indexUrl}/signin`, s = {
      email: t,
      password: e
    }, o = {
      "Content-Type": "application/json"
    };
    return l.post(n, s, {
      headers: o
    }).then((a) => (this.token = a.data.response.idToken, !!this.token)).catch((a) => a);
  }
  indexFilePart(t, e) {
    const n = new FormData();
    n.append("file", t), n.append("partition", e);
    const s = {
      "Content-Type": "multipart/form-data"
    };
    return this.post(this.indexUrl, "/upload_part", n, s);
  }
  async healthCheck() {
    return l.get(`${this.indexUrl}/healthcheck`).then((t) => t.data);
  }
  async searchImage(t, e) {
    const n = new FormData();
    return n.append("prompt", t), n.append("count", e.toString()), this.post(this.indexUrl, "/find_image", n);
  }
  async searchElementImage(t, e) {
    const n = new FormData();
    return n.append("prompt", t), n.append("count", e.toString()), this.post(this.indexUrl, "/find_element_image", n);
  }
  async listElementText(t) {
    const e = new FormData();
    return e.append("key", t), this.post(this.indexUrl, "/list_element_text", e);
  }
  async autocompleteSelectElementImagePrompt(t, e) {
    const n = `${this.indexUrl}/autocomplete_select_element_image_prompt`, s = {
      token: this.token,
      id: t,
      prefix: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => []);
  }
  async changeBackgroundImage(t, e) {
    const n = `${this.indexUrl}/change_background_image`, s = {
      token: this.token,
      foreground_key: t,
      background_prompt: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => []);
  }
  async editElementImage(t, e) {
    const n = `${this.indexUrl}/edit_element_image`, s = {
      token: this.token,
      key: t,
      prompt: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => []);
  }
  async autocompleteNewElementImagePrompt(t, e) {
    const n = `${this.indexUrl}/autocomplete_new_element_image_prompt`, s = {
      token: this.token,
      id: t,
      prefix: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => []);
  }
  async listBackgroundPrompt(t) {
    const e = `${this.indexUrl}/list_background_prompt`, n = {
      token: this.token,
      key: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async listKeyword(t) {
    const e = `${this.indexUrl}/list_keyword`, n = {
      token: this.token,
      key: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async listBrandStory() {
    const t = new FormData();
    return this.post(this.indexUrl, "/list_brand_story", t);
  }
  async listCreativeGuideline() {
    const t = new FormData();
    return this.post(this.indexUrl, "/list_creative_guideline", t);
  }
  async listCommerceChannel() {
    const t = new FormData();
    return this.post(this.indexUrl, "/list_commerce_channel", t);
  }
  async reshapeImage(t, e, n) {
    const s = new FormData();
    return s.append("key", t), s.append("new_width", e.toString()), s.append("new_height", n.toString()), this.post(this.indexUrl, "/reshape_image", s);
  }
  async attachBrand(t, e) {
    const n = {
      email: t,
      token: this.token,
      url: e
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post("https://piggy.hedwigai.com/branding", n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async attachBrandStatus(t) {
    const e = {
      email: t
    }, n = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post("https://piggy.hedwigai.com/status", e, {
      headers: n
    }).then((s) => (this.working = !1, s.data)).catch((s) => []);
  }
  async getActiveBrand() {
    const t = `${this.indexUrl}/get_active_brand`, e = {
      token: this.token
    }, n = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(t, e, {
      headers: n
    }).then((s) => (this.working = !1, s.data)).catch((s) => []);
  }
  async getActiveProduct() {
    const t = `${this.indexUrl}/get_active_product`, e = {
      token: this.token
    }, n = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(t, e, {
      headers: n
    }).then((s) => (this.working = !1, s.data)).catch((s) => []);
  }
  async updateGenerateData(t, e, n) {
    const s = `${this.indexUrl}/update_generate_data`, o = {
      token: this.token,
      batch: t,
      old_key: e,
      new_key: n
    }, a = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(s, o, {
      headers: a
    }).then((i) => (this.working = !1, i.data)).catch((i) => []);
  }
  async setActiveProduct(t) {
    const e = `${this.indexUrl}/set_active_product`, n = {
      token: this.token,
      product_name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async firstRenderImage(t, e) {
    const n = `${this.indexUrl}/first_render_image`, s = {
      token: this.token,
      key: t,
      metadata: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => []);
  }
  async getGenerateData(t) {
    const e = `${this.indexUrl}/get_generate_data_list`, n = {
      token: this.token,
      count: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async saveGenerateData(t, e, n, s, o) {
    const a = {
      token: this.token,
      batch: t,
      prompt: e,
      element_name: n,
      element_key: s,
      work: o
    }, i = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(`${this.indexUrl}/save_generate_data`, a, {
      headers: i
    }).then((d) => (this.working = !1, d.data)).catch((d) => []);
  }
  async getPromptGuide() {
    const t = {
      token: this.token
    }, e = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(`${this.indexUrl}/get_prompt_guide`, t, {
      headers: e
    }).then((n) => (this.working = !1, n.data)).catch((n) => []);
  }
  async generateImageFromElementImageProductKey(t, e) {
    const n = `${this.indexUrl}/generate_image_from_element_image_product_key`, s = {
      token: this.token,
      key: t,
      metadata: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => []);
  }
  async setActiveBrand(t) {
    const e = `${this.indexUrl}/set_active_brand`, n = {
      token: this.token,
      brand_name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async lockSecret(t, e) {
    const n = `${this.indexUrl}/lock_secret`, s = {
      token: this.token,
      key: t,
      metadata: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => []);
  }
  async unlockSecret(t) {
    const e = `${this.indexUrl}/unlock_secret`, n = {
      seed: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async listElementImageProduct(t) {
    const e = `${this.indexUrl}/list_element_image_product`, n = {
      token: this.token,
      count: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async searchImageBackground(t, e) {
    const n = new FormData();
    return n.append("prompt", t), n.append("count", e.toString()), this.post(this.indexUrl, "/find_image_background", n);
  }
  async scheduleDemo(t, e, n) {
    const s = new FormData();
    return s.append("email", t), s.append("datetime", e), s.append("timezone", n), this.post(this.indexUrl, "/schedule_demo", s);
  }
  async bookDemo(t, e) {
    const n = new FormData();
    return n.append("email", t), n.append("secret_code", e), this.post(this.indexUrl, "/book_demo", n);
  }
  async generateElementImage(t, e, n) {
    const s = new FormData();
    return s.append("prompt", t), s.append("width", e), s.append("height", n), this.post(this.indexUrl, "/generate_element_image", s);
  }
  async generateElementImageWithBackground(t, e) {
    const n = new FormData();
    return n.append("prompt", e), n.append("background_key", t), this.post(this.indexUrl, "/generate_element_image_with_background", n);
  }
  async similarElementImageWithBackground(t, e) {
    const n = new FormData();
    return n.append("element_key", e), n.append("background_key", t), this.post(this.indexUrl, "/similar_element_image_with_background", n);
  }
  async similarElementTextWithBackground(t, e) {
    const n = new FormData();
    return n.append("background_key", t), n.append("text_value", e), this.post(this.indexUrl, "/similar_element_text_with_background", n);
  }
  async selectElementText(t) {
    const e = `${this.indexUrl}/select_element_text`, n = {
      token: this.token,
      key: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async conductor(t) {
    const e = `${this.indexUrl}/conductor`, n = {
      token: this.token,
      data: {
        canvas: t
      }
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async selectElementImage(t, e, n) {
    const s = new FormData();
    return s.append("prompt", e), s.append("key", t), s.append("points", n.map((o) => o.join("+")).join(",")), this.post(this.indexUrl, "/select_element_image", s);
  }
  async upscaleImage(t) {
    const e = new FormData();
    return e.append("key", t), this.post(this.indexUrl, "/upscale_image", e);
  }
  async relitBackgroundWithForeground(t, e) {
    const n = new FormData();
    return n.append("background_key", t), n.append("foreground_key", e), this.post(this.indexUrl, "/relit_background_with_foreground", n);
  }
  async indexFilePartComplete(t, e, n) {
    this.working = !0;
    const s = `${this.indexUrl}/upload_complete`, o = {
      token: this.token,
      partition: n,
      output_file_name: t,
      remote_files: e.map((i) => i.filePath)
    }, a = {
      "Content-Type": "application/json"
    };
    return l.post(s, o, {
      timeout: 60 * 60 * 1e3,
      headers: a
    }).then((i) => {
      if (this.working = !1, i.status == 200)
        return i.data;
      throw new Error(i.statusText);
    }).catch((i) => {
      throw this.working = !1, new Error(i);
    });
  }
  indexLargeFile(t, e, n) {
    let o = 0, a = [];
    const i = () => {
      const h = new FileReader(), f = t.slice(o, o + 1048576);
      h.onload = (k) => {
        if (k.target == null)
          return;
        const w = k.target.result;
        if (!(w instanceof ArrayBuffer))
          return;
        const m = (n ? `${n}/` : "") + t.name + `_part/${o}.octet`;
        a.push({
          filePath: m,
          success: !1
        }), u(m, w), o += 1048576, o < t.size && i();
      }, h.readAsArrayBuffer(f);
    }, d = t.name, u = (h, f) => {
      const k = new Uint8Array(f), w = new Blob([k]), g = new File([w], h, { type: "application/octet-stream" });
      this.indexFilePart(g, n).then((m) => {
        if (m.meta == "success")
          if (a.find((p) => {
            if (p.filePath == h)
              return p.success = !0, !0;
          }), a.every((p) => p.success))
            this.indexFilePartComplete(d, a, n).then((p) => {
              e(100);
            });
          else {
            const p = Math.round(a.filter((_) => _.success).length * 100 / a.length);
            e(p);
          }
        else
          u(h, f);
      }).catch((m) => {
        u(h, f);
      });
    };
    i();
  }
  saveWorkSnapshot(t, e) {
    const n = e.replace(/^data:image\/png;base64,/, ""), o = Nn(n, "image/png"), i = $n(o, "current.png"), d = new FormData();
    d.append("file", i), d.append("partition", `work/${t}`);
    const u = {
      "Content-Type": "multipart/form-data"
    };
    return this.post(this.indexUrl, "/copy", d, u);
  }
  indexFile(t) {
    const e = new FormData();
    e.append("file", t);
    const n = {
      "Content-Type": "multipart/form-data"
    };
    return this.post(this.indexUrl, "/upload", e, n);
  }
  uploadProduct(t, e) {
    const n = new FormData();
    n.append("name", t), n.append("file", e);
    const s = {
      "Content-Type": "multipart/form-data"
    };
    return this.post(this.indexUrl, "/upload_product", n, s);
  }
  async indexCacheFile(t, e) {
    this.working = !0;
    const n = `${this.indexUrl}/upload_file_cache`, s = {
      token: this.token,
      image: t,
      key: e
    }, o = {
      "Content-Type": "application/json"
    };
    return l.post(n, s, {
      timeout: 60 * 60 * 1e3,
      headers: o
    }).then((a) => {
      if (this.working = !1, a.status == 200)
        return a.data;
      throw new Error(a.statusText);
    }).catch((a) => {
      throw this.working = !1, new Error(a);
    });
  }
  async deleteCacheFile(t) {
    this.working = !0;
    const e = `${this.indexUrl}/delete_file_cache`, n = {
      token: this.token,
      key: t
    }, s = {
      "Content-Type": "application/json"
    };
    return l.post(e, n, {
      timeout: 60 * 60 * 1e3,
      headers: s
    }).then((o) => {
      if (this.working = !1, o.status == 200)
        return o.data;
      throw new Error(o.statusText);
    }).catch((o) => {
      throw this.working = !1, new Error(o);
    });
  }
  async getPreferences() {
    this.working = !0;
    const t = `${this.indexUrl}/get_preferences`, e = {
      token: this.token
    }, n = {
      "Content-Type": "application/json"
    };
    return l.post(t, e, {
      timeout: 60 * 60 * 1e3,
      headers: n
    }).then((s) => {
      if (this.working = !1, s.status == 200)
        return s.data;
      throw new Error(s.statusText);
    }).catch((s) => {
      throw this.working = !1, new Error(s);
    });
  }
  async setPreferences(t) {
    this.working = !0;
    const e = `${this.indexUrl}/set_preferences`, n = {
      token: this.token,
      metadata: t
    }, s = {
      "Content-Type": "application/json"
    };
    return l.post(e, n, {
      timeout: 60 * 60 * 1e3,
      headers: s
    }).then((o) => {
      if (this.working = !1, o.status == 200)
        return o.data;
      throw new Error(o.statusText);
    }).catch((o) => {
      throw this.working = !1, new Error(o);
    });
  }
  async indexFileBlob(t, e, n) {
    this.working = !0;
    const s = `${this.indexUrl}/upload_blob`, o = {
      blob: t,
      fileName: e,
      token: this.token,
      partition: n
    }, a = {
      "Content-Type": "application/json"
    };
    return l.post(s, o, {
      timeout: 60 * 60 * 1e3,
      headers: a
    }).then((i) => {
      if (this.working = !1, i.status == 200)
        return i.data;
      throw new Error(i.statusText);
    }).catch((i) => {
      throw this.working = !1, new Error(i);
    });
  }
  async getWorkspace() {
    const t = `${this.indexUrl}/get_workspace`, e = {
      token: this.token
    }, n = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(t, e, {
      headers: n
    }).then((s) => (this.working = !1, s.data)).catch((s) => s);
  }
  async setWorkspaceName(t) {
    const e = `${this.indexUrl}/set_workspace_name`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => o);
  }
  async setWorkspaceLogo(t) {
    const e = t, n = `${this.indexUrl}/set_workspace_logo`, s = {
      token: this.token,
      image: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => a);
  }
  getInvoiceList() {
    const t = `${this.indexUrl}/get_invoice_list`, e = {
      token: this.token
    }, n = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(t, e, {
      headers: n
    }).then((s) => (this.working = !1, s.data)).catch((s) => []);
  }
  async getIntegrationList() {
    const t = `${this.indexUrl}/get_integration_list`, e = {
      token: this.token
    }, n = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(t, e, {
      headers: n
    }).then((s) => (this.working = !1, s.data)).catch((s) => s);
  }
  async getMemberList() {
    const t = `${this.indexUrl}/get_member_list`, e = {
      token: this.token
    }, n = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(t, e, {
      headers: n
    }).then((s) => (this.working = !1, s.data)).catch((s) => s);
  }
  async waitlistMember(t, e) {
    const n = `${this.indexUrl}/waitlist_account`, s = {
      email: t,
      password: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => a);
  }
  async forgotPassword(t) {
    const e = `${this.indexUrl}/forgot_password`, n = {
      email: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => o);
  }
  async magicLink(t, e) {
    const n = `${this.indexUrl}/signin_with_email_and_secret`, s = {
      e: t,
      s: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, this.token = a.data.response.idToken, a.data)).catch((a) => a);
  }
  async changePassword(t) {
    const e = `${this.indexUrl}/change_password`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, this.token = o.data.idToken, !!this.token)).catch((o) => o);
  }
  async billAccountCredit(t) {
    const e = `${this.indexUrl}/set_bill_account_credit`, n = {
      "Content-Type": "application/json"
    }, s = {
      token: this.token,
      amount: t
    };
    return this.working = !0, l.post(e, s, {
      headers: n
    }).then((o) => (this.working = !1, o.data)).catch((o) => o);
  }
  async billAccountCreditCheckout(t, e) {
    const n = `${this.indexUrl}/set_bill_account_credit_checkout`, s = {
      "Content-Type": "application/json"
    }, o = {
      token: e,
      checkout_id: t
    };
    return this.working = !0, l.post(n, o, {
      headers: s
    }).then((a) => (this.working = !1, a.data)).catch((a) => a);
  }
  async billAccountSubscription() {
    const t = `${this.indexUrl}/set_bill_account_subscription`, e = {
      "Content-Type": "application/json"
    }, n = {
      token: this.token
    };
    return this.working = !0, l.post(t, n, {
      headers: e
    }).then((s) => (this.working = !1, s.data)).catch((s) => s);
  }
  async billAccountSubscriptionCheckout(t, e) {
    const n = `${this.indexUrl}/set_bill_account_subscription_checkout`, s = {
      "Content-Type": "application/json"
    }, o = {
      token: e,
      checkout_id: t
    };
    return this.working = !0, l.post(n, o, {
      headers: s
    }).then((a) => (this.working = !1, a.data)).catch((a) => a);
  }
  async billAccountSubscriptionCancellation() {
    const t = `${this.indexUrl}/set_bill_account_subscription_cancellation`, e = {
      "Content-Type": "application/json"
    }, n = {
      token: this.token
    };
    return this.working = !0, l.post(t, n, {
      headers: e
    }).then((s) => (this.working = !1, s.data)).catch((s) => s);
  }
  async requestAccountIntegration(t) {
    const e = `${this.indexUrl}/request_account_integration`, n = {
      "Content-Type": "application/json"
    }, s = {
      token: this.token,
      name: t
    };
    return this.working = !0, l.post(e, s, {
      headers: n
    }).then((o) => (this.working = !1, o.data)).catch((o) => o);
  }
  async activateMember(t, e) {
    const n = `${this.indexUrl}/activate_account`, s = {
      e: t,
      s: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => {
      this.working = !1;
      const i = a.data.response;
      return this.token = i.idToken, this.token ? i : {};
    }).catch((a) => a);
  }
  async findTypography(t) {
    const e = `${this.indexUrl}/find_typography`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => o);
  }
  async inviteMember(t) {
    const e = `${this.indexUrl}/invite_account`, n = {
      token: this.token,
      email: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => o);
  }
  async deleteMember(t) {
    const e = `${this.indexUrl}/delete_account`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => o);
  }
  async getMember() {
    const t = `${this.indexUrl}/get_member`, e = {
      token: this.token
    }, n = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(t, e, {
      headers: n
    }).then((s) => (this.working = !1, s.data)).catch((s) => s);
  }
  async setMemberName(t) {
    const e = `${this.indexUrl}/set_member_name`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => o);
  }
  async setMemberLogo(t) {
    const e = `${this.indexUrl}/set_member_logo`, n = t, s = {
      token: this.token,
      name: n
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => a);
  }
  async setMemberGender(t) {
    const e = `${this.indexUrl}/set_member_gender`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => o);
  }
  async setMemberGeneration(t) {
    const e = `${this.indexUrl}/set_member_generation`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => o);
  }
  async setMemberLocation(t) {
    const e = `${this.indexUrl}/set_member_location`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => o);
  }
  async setMemberAccessLevel(t, e) {
    const n = `${this.indexUrl}/set_member_access_level`, s = {
      token: this.token,
      email: t,
      access_level: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => a);
  }
  async getAccountCredit() {
    const t = `${this.indexUrl}/get_account_credit`, e = {
      token: this.token
    }, n = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(t, e, {
      headers: n
    }).then((s) => (this.working = !1, s.data)).catch((s) => s);
  }
  async getAccountDebit() {
    const t = `${this.indexUrl}/get_account_debit`, e = {
      token: this.token
    }, n = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(t, e, {
      headers: n
    }).then((s) => (this.working = !1, s.data)).catch((s) => s);
  }
  async setWorkMeta(t, e, n, s) {
    const o = `${this.indexUrl}/set_work_meta`, a = {
      token: this.token,
      work_id: t,
      name: e,
      thumbnail: n,
      last_modified: s
    }, i = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(o, a, {
      headers: i
    }).then((d) => (this.working = !1, d.data)).catch((d) => d);
  }
  async setWorkName(t, e) {
    const n = `${this.indexUrl}/set_work_name`, s = {
      token: this.token,
      work_id: t,
      data: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => a);
  }
  async setWorkThumbnail(t, e) {
    const n = `${this.indexUrl}/set_work_thumbnail`, s = {
      token: this.token,
      work_id: t,
      data: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => a);
  }
  async setWorkLastModified(t, e) {
    const n = `${this.indexUrl}/set_work_last_modified`, s = {
      token: this.token,
      work_id: t,
      data: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => a);
  }
  async getWorkList() {
    const t = `${this.indexUrl}/get_work_list`, e = {
      token: this.token
    }, n = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(t, e, {
      headers: n
    }).then((s) => (this.working = !1, s.data)).catch((s) => s);
  }
  async getWorkMeta(t) {
    const e = `${this.indexUrl}/get_work_meta`, n = {
      token: this.token,
      work_id: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => o);
  }
  async getWorkData(t) {
    const e = `${this.indexUrl}/get_work_data`, n = {
      token: this.token,
      work_id: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => o);
  }
  async setWorkData(t, e) {
    const n = `${this.indexUrl}/set_work_data`, s = {
      token: this.token,
      work_id: t,
      data: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => a);
  }
  async deleteWork(t) {
    const e = `${this.indexUrl}/delete_work`, n = {
      token: this.token,
      work_id: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => o);
  }
  async getFileUrl(t) {
    typeof t == "string" && (t = [t]);
    const e = `${this.indexUrl}/get_file_url`, n = {
      token: this.token,
      key: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async setBrandMission(t, e) {
    const n = `${this.indexUrl}/set_brand_mission`, s = {
      token: this.token,
      key: t,
      value: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => []);
  }
  async getBrandMission(t) {
    const e = `${this.indexUrl}/get_brand_mission`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async setBrandLogo(t, e, n, s, o) {
    const a = `${this.indexUrl}/set_brand_logo`, i = {
      token: this.token,
      key: t,
      fileId: e,
      lockupSpace: n,
      clearSpace: s,
      lockupValue: o
    }, d = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(a, i, {
      headers: d
    }).then((u) => (this.working = !1, u.data)).catch((u) => []);
  }
  async getBrandLogo(t) {
    const e = `${this.indexUrl}/get_brand_logo`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async setBrandPrimaryPalette(t, e) {
    const n = `${this.indexUrl}/set_brand_primary_palette`, s = {
      token: this.token,
      key: t,
      value: e.join(",")
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => []);
  }
  async setBrandSecondaryPalette(t, e) {
    const n = `${this.indexUrl}/set_brand_secondary_palette`, s = {
      token: this.token,
      key: t,
      value: e.join(",")
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => []);
  }
  async setBrandTertiaryPalette(t, e) {
    const n = `${this.indexUrl}/set_brand_tertiary_palette`, s = {
      token: this.token,
      key: t,
      value: e.join(",")
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => []);
  }
  async getBrandPalette(t) {
    const e = `${this.indexUrl}/get_brand_palette`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async setBrandHeadingFont(t, e, n) {
    const s = `${this.indexUrl}/set_brand_heading_font`, o = {
      token: this.token,
      brandId: t,
      family: e,
      files: n
    }, a = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(s, o, {
      headers: a
    }).then((i) => (this.working = !1, i.data)).catch((i) => []);
  }
  async setBrandBodyFont(t, e, n) {
    const s = `${this.indexUrl}/set_brand_body_font`, o = {
      token: this.token,
      brandId: t,
      family: e,
      files: n
    }, a = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(s, o, {
      headers: a
    }).then((i) => (this.working = !1, i.data)).catch((i) => []);
  }
  async getBrandHeadingFont(t) {
    const e = `${this.indexUrl}/get_brand_heading_font`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async getBrandBodyFont(t) {
    const e = `${this.indexUrl}/get_brand_body_font`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async setBrandFormat(t, e) {
    const n = `${this.indexUrl}/set_brand_format`, s = {
      token: this.token,
      brandId: t,
      format: e
    }, o = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(n, s, {
      headers: o
    }).then((a) => (this.working = !1, a.data)).catch((a) => []);
  }
  async getBrandFormat(t) {
    const e = `${this.indexUrl}/set_brand_format`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
  async getWorkTemplate(t) {
    const e = `${this.indexUrl}/get_work_template`, n = {
      token: this.token,
      name: t
    }, s = {
      "Content-Type": "application/json"
    };
    return this.working = !0, l.post(e, n, {
      headers: s
    }).then((o) => (this.working = !1, o.data)).catch((o) => []);
  }
}
class Wn {
  agent;
  constructor() {
    this.agent = new Mn();
  }
  /**
   * signIn
   * @returns true if the sign in was successful
   * @category Security and User Management
   */
  async signIn(t, e) {
    return this.agent.signIn(t, e);
  }
  /*
   * setIdToken
   * @category Security and User Management
   */
  setIdToken(t) {
    this.agent.token = t;
  }
  /**
   * getIdToken
   * @category Security and User Management
   */
  getIdToken() {
    return this.agent.token;
  }
  /**
   * isWorking
   * @category Creative Hub
   */
  async isWorking() {
    return this.agent.isWorking();
  }
  /**
   * health
   * @returns true if the service is healthy
   * @category Security and User Management
   */
  async healthCheck() {
    return this.agent.healthCheck();
  }
  /**
   * storeFile
   * @param file: File - the file to upload
   * @returns The key in the knowledge graph for the asset
   * @category Creative Hub
   */
  async indexFile(t) {
    return this.agent.indexFile(t);
  }
  async getPreferences() {
    return this.agent.getPreferences();
  }
  async setPreferences(t) {
    return this.agent.setPreferences(t);
  }
  async indexCacheFile(t, e = "") {
    return this.agent.indexCacheFile(t, e);
  }
  async deleteCacheFile(t) {
    return this.agent.deleteCacheFile(t);
  }
  /**
   * saveWorkSnapshot
   * @param file: File - the file to upload
   * @returns The key in the knowledge graph for the asset
   * @category Creative Hub
   */
  async saveWorkSnapshot(t, e) {
    return this.agent.saveWorkSnapshot(t, e);
  }
  /**
   * saveWorkSnapshot
   * @param file: File - the file to upload
   * @returns The key in the knowledge graph for the asset
   * @category Creative Hub
   */
  async relitBackgroundWithForeground(t, e) {
    return this.agent.relitBackgroundWithForeground(t, e);
  }
  async upscaleImage(t) {
    return this.agent.upscaleImage(t);
  }
  /**
   * storeFile
   * @param file: File - the file to upload
   * @returns The key in the knowledge graph for the asset
   * @category Creative Hub
   */
  async indexLargeFile(t, e, n = "") {
    return this.agent.indexLargeFile(t, e, n);
  }
  /**
   * @category Creative Hub
   */
  async indexFileBlob(t, e, n = "") {
    return this.agent.indexFileBlob(t, e, n);
  }
  /**
   * @category Creative Hub
   */
  async getFileUrl(t) {
    return this.agent.getFileUrl(t);
  }
  /**
   * @category Creative Hub
   */
  async searchImage(t, e) {
    return this.agent.searchImage(t, e);
  }
  /**
   * @category Creative Hub
   */
  async searchElementImage(t, e) {
    return this.agent.searchElementImage(t, e);
  }
  /**
   * @category Creative Hub
   */
  async listElementText(t) {
    return this.agent.listElementText(t);
  }
  /**
   * @category Creative Hub
   */
  async listElementProduct(t) {
    return this.agent.listElementImageProduct(t);
  }
  async lockSecret(t, e) {
    return this.agent.lockSecret(t, e);
  }
  async unlockSecret(t) {
    return this.agent.unlockSecret(t);
  }
  async listCreativeGuideline() {
    return this.agent.listCreativeGuideline();
  }
  async listCommerceChannel() {
    return this.agent.listCommerceChannel();
  }
  async listBrandStory() {
    return this.agent.listBrandStory();
  }
  async getActiveProduct() {
    return this.agent.getActiveProduct();
  }
  async setActiveProduct(t) {
    return this.agent.setActiveProduct(t);
  }
  async scheduleDemo(t, e, n) {
    return this.agent.scheduleDemo(t, e, n);
  }
  async bookDemo(t, e) {
    return this.agent.bookDemo(t, e);
  }
  async updateGenerateData(t, e, n) {
    return this.agent.updateGenerateData(t, e, n);
  }
  async firstRenderImage(t, e) {
    return this.agent.firstRenderImage(t, e);
  }
  async getGenerateData(t) {
    return this.agent.getGenerateData(t);
  }
  async getPromptGuide() {
    return this.agent.getPromptGuide();
  }
  async saveGenerateData(t, e, n, s, o) {
    return this.agent.saveGenerateData(t, e, n, s, o);
  }
  async generateImageFromElementImageProductKey(t, e) {
    return this.agent.generateImageFromElementImageProductKey(t, e);
  }
  async getActiveBrand() {
    return this.agent.getActiveBrand();
  }
  async setActiveBrand(t) {
    return this.agent.setActiveBrand(t);
  }
  /**
   * @category Creative Hub
   */
  async conductor(t) {
    return this.agent.conductor(t);
  }
  /**
   * @category Creative Hub
   */
  async searchImageBackground(t, e) {
    return this.agent.searchImageBackground(t, e);
  }
  /**
   * @category Creative Hub
   */
  async findTypography(t) {
    return this.agent.findTypography(t);
  }
  /**
   * @category Brand Book
   */
  async getBrandMission(t) {
    return this.agent.getBrandMission(t);
  }
  /**
   * @category Brand Book
   */
  async setBrandMission(t, e) {
    return this.agent.setBrandMission(t, e);
  }
  /**
   * @category Brand Book
   */
  async getBrandLogo(t) {
    return this.agent.getBrandLogo(t);
  }
  /**
   * @category Brand Book
   */
  async setBrandLogo(t, e, n, s, o) {
    return this.agent.setBrandLogo(t, e, n, s, o);
  }
  async autocompleteSelectElementImagePrompt(t, e) {
    return this.agent.autocompleteSelectElementImagePrompt(t, e);
  }
  async changeBackgroundImage(t, e) {
    return this.agent.changeBackgroundImage(t, e);
  }
  async editElementImage(t, e) {
    return this.agent.editElementImage(t, e);
  }
  async reshapeImage(t, e, n) {
    return this.agent.reshapeImage(t, e, n);
  }
  async autocompleteNewElementImagePrompt(t, e) {
    return this.agent.autocompleteNewElementImagePrompt(t, e);
  }
  async listBackgroundPrompt(t) {
    return this.agent.listBackgroundPrompt(t);
  }
  async listKeyword(t) {
    return this.agent.listKeyword(t);
  }
  async attachBrand(t, e) {
    return this.agent.attachBrand(t, e);
  }
  async uploadProduct(t, e) {
    return this.agent.uploadProduct(t, e);
  }
  async attachBrandStatus(t) {
    return this.agent.attachBrandStatus(t);
  }
  /**
   * @category Brand Book
   */
  async setBrandPrimaryPalette(t, e) {
    return this.agent.setBrandPrimaryPalette(t, e);
  }
  /**
   * @category Brand Book
   */
  async setBrandSecondaryPalette(t, e) {
    return this.agent.setBrandSecondaryPalette(t, e);
  }
  /**
   * @category Brand Book
   */
  async setBrandTertiaryPalette(t, e) {
    return this.agent.setBrandTertiaryPalette(t, e);
  }
  /**
   * @category Brand Book
   */
  async getBrandPalette(t) {
    return this.agent.getBrandPalette(t);
  }
  /**
   * @category Brand Book
   */
  async setBrandHeadingFont(t, e, n) {
    return this.agent.setBrandHeadingFont(t, e, n);
  }
  /**
   * @category Brand Book
   */
  async setBrandBodyFont(t, e, n) {
    return this.agent.setBrandBodyFont(t, e, n);
  }
  /**
   * @category Brand Book
   */
  async getBrandHeadingFont(t) {
    return this.agent.getBrandHeadingFont(t);
  }
  /**
   * @category Brand Book
   */
  async getBrandBodyFont(t) {
    return this.agent.getBrandBodyFont(t);
  }
  /**
   * @category Brand Book
   */
  async setBrandFormat(t, e) {
    return this.agent.setBrandFormat(t, e);
  }
  /**
   * @category Brand Book
   */
  async getBrandFormat(t) {
    return this.agent.getBrandFormat(t);
  }
  async generateElementImage(t, e = 512, n = 768) {
    return this.agent.generateElementImage(t, `${e}`, `${n}`);
  }
  async generateElementImageWithBackground(t, e) {
    return this.agent.generateElementImageWithBackground(t, e);
  }
  async similarElementImageWithBackground(t, e) {
    return this.agent.similarElementImageWithBackground(t, e);
  }
  async similarElementTextWithBackground(t, e) {
    return this.agent.similarElementTextWithBackground(t, e);
  }
  async selectElementImage(t, e, n = [[0]]) {
    return this.agent.selectElementImage(t, e, n);
  }
  async selectElementText(t) {
    return this.agent.selectElementText(t);
  }
  /**
   * @category Security and User Management
   */
  async getWorkspace() {
    return this.agent.getWorkspace();
  }
  /**
   * @category Security and User Management
   */
  async setWorkspaceName(t) {
    return this.agent.setWorkspaceName(t);
  }
  /**
   * @category Security and User Management
   */
  async setWorkspaceLogo(t) {
    return new Promise((e, n) => {
      const s = new FileReader();
      s.onload = () => {
        const o = s.result;
        e(this.agent.setWorkspaceLogo(o));
      }, s.onerror = () => {
        n(new Error("Error reading blob"));
      }, s.readAsDataURL(t);
    });
  }
  /**
   * @category Security and User Management
   */
  async getInvoiceList() {
    return this.agent.getInvoiceList();
  }
  /**
   * @category Security and User Management
   */
  async getMemberList() {
    return this.agent.getMemberList();
  }
  /**
   * @category Security and User Management
   */
  async getIntegrationList() {
    return this.agent.getIntegrationList();
  }
  /**
   * @category Security and User Management
   */
  async getMember() {
    return this.agent.getMember();
  }
  /**
   * @category Security and User Management
   */
  async waitlistMember(t, e) {
    return this.agent.waitlistMember(t, e);
  }
  /**
   * @category Security and User Management
   */
  async activateMember(t, e) {
    return this.agent.activateMember(t, e);
  }
  /**
   * @category Security and User Management
   */
  async billAccountSubscription() {
    return this.agent.billAccountSubscription();
  }
  /**
   * @category Security and User Management
   */
  async billAccountSubscriptionCheckout(t, e) {
    return this.agent.billAccountSubscriptionCheckout(t, e);
  }
  /**
   * @category Security and User Management
   */
  async billAccountCredit(t) {
    return this.agent.billAccountCredit(t);
  }
  /**
   * @category Security and User Management
   */
  async billAccountCreditCheckout(t, e) {
    return this.agent.billAccountCreditCheckout(t, e);
  }
  /**
   * @category Security and User Management
   */
  async billAccountSubscriptionCancellation() {
    return this.agent.billAccountSubscriptionCancellation();
  }
  /**
   * @category Security and User Management
   */
  async requestAccountIntegration(t) {
    return this.agent.requestAccountIntegration(t);
  }
  /**
   * @category Security and User Management
   */
  async deleteMember(t) {
    return this.agent.deleteMember(t);
  }
  /**
   * @category Security and User Management
   */
  async inviteMember(t) {
    return this.agent.inviteMember(t);
  }
  /**
   * @category Security and User Management
   */
  async forgotPassword(t) {
    return this.agent.forgotPassword(t);
  }
  /**
   * @category Security and User Management
   */
  async magicLink(t, e) {
    return this.agent.magicLink(t, e);
  }
  /**
   * @category Security and User Management
   */
  async changePassword(t) {
    return this.agent.changePassword(t);
  }
  /**
   * @category Security and User Management
   */
  async setMemberName(t) {
    return this.agent.setMemberName(t);
  }
  /**
   * @category Security and User Management
   */
  async setMemberGender(t) {
    return this.agent.setMemberGender(t);
  }
  /**
   * @category Security and User Management
   */
  async setMemberGeneration(t) {
    return this.agent.setMemberGeneration(t);
  }
  /**
   * @category Security and User Management
   */
  async setMemberLogo(t) {
    return this.agent.setMemberLogo(t);
  }
  /**
   * @category Security and User Management
   */
  async setMemberLocation(t) {
    return this.agent.setMemberLocation(t);
  }
  /**
   * @category Security and User Management
   */
  async setMemberAccessLevel(t, e) {
    return this.agent.setMemberAccessLevel(t, e);
  }
  /**
   * @category Security and User Management
   */
  async getAccountCredit() {
    return this.agent.getAccountCredit();
  }
  /**
   * @category Security and User Management
   */
  async getAccountDebit() {
    return this.agent.getAccountDebit();
  }
  /**
   * @category Studio
   */
  async setWorkMeta(t, e, n, s) {
    return this.agent.setWorkMeta(t, e, n, s);
  }
  /**
   * @category Studio
   */
  async setWorkName(t, e) {
    return this.agent.setWorkName(t, e);
  }
  /**
   * @category Studio
   */
  async setWorkThumbnail(t, e) {
    return this.agent.setWorkThumbnail(t, e);
  }
  /**
   * @category Studio
   */
  async setWorkLastModified(t, e) {
    return this.agent.setWorkLastModified(t, e);
  }
  /**
   * @category Studio
   */
  async getWorkMeta(t) {
    return this.agent.getWorkMeta(t);
  }
  /**
   * @category Studio
   */
  async getWorkList() {
    return this.agent.getWorkList();
  }
  /**
   * @category Studio
   */
  async setWorkData(t, e) {
    return this.agent.setWorkData(t, e);
  }
  /**
   * @category Studio
   */
  async getWorkTemplate(t) {
    return this.agent.getWorkTemplate(t);
  }
  /**
   * @category Studio
   */
  async getWorkData(t) {
    return this.agent.getWorkData(t);
  }
  /**
   * @category Studio
   */
  async deleteWork(t) {
    return this.agent.deleteWork(t);
  }
}
export {
  Ln as AspectRatio,
  In as GenerateStyle,
  Wn as Library
};
