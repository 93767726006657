import React, { useEffect } from "react";
import Asset from "../../Asset";
import { Library } from "hedwigai";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../lib/Route";
import toast from "react-hot-toast";
import { validateSignUpForm, ValidationMessages } from "./utils";
import { useAccountStore } from "../../lib/state";

interface SignUpProps {
  library: Library;
}

enum SignUpState {
  SIGNING,
  SIGNED,
}

const SignUp: React.FC<SignUpProps> = ({ library }) => {
  const navigate = useNavigate();
  const token = useAccountStore((state) => state.token);
  const [signUpState, setSignUpState] = React.useState<SignUpState | undefined>(
    undefined,
  );
  const [validationMessage, setValidationMessage] = React.useState<
    ValidationMessages | undefined
  >(undefined);

  useEffect(() => {
    if (token) {
      navigate(ROUTES.STUDIO);
    }
  }, [token]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const username = e.currentTarget["username"].value;
    const password = e.currentTarget["password"].value;
    setSignUpState(SignUpState.SIGNING);

    if (validateSignUpForm(username, password, setValidationMessage)) {
      library
        .waitlistMember(username, password)
        .then((r: any) => {
          setSignUpState(SignUpState.SIGNED);
        })
        .catch((e) => {
          setSignUpState(undefined);
          toast.error("Sign Up failed");
          toast("Please try again", { icon: "ℹ️" });
          console.log("Error signing up", e);
        });
    }
  };

  return (
    <div className="auth-page">
      <div className="header">
        <img
          src={Asset.LOGO_IMAGE}
          alt="logo-image"
          className="logo-image"
          onClick={() => navigate(ROUTES.MAIN)}
        />
        <h1>Create an account</h1>
      </div>
      {signUpState === SignUpState.SIGNED ? (
        <div className="auth-message">
          <h3>Almost there!</h3>
          <p>
            Please check your inbox for a verification email to complete your
            account setup. Didn't receive it? Be sure to check your spam or junk
            folder.
          </p>
          <span className="redirect" onClick={() => navigate(ROUTES.MAIN)}>
            Back to home
          </span>
        </div>
      ) : (
        <form onSubmit={handleFormSubmit}>
          <div className="section">
            <label htmlFor="username">Email *</label>
            <input
              type="text"
              id="username"
              name="username"
              autoCapitalize="none"
            />
          </div>
          <div className="section">
            <label htmlFor="password">Password *</label>
            <input type="password" id="password" name="password" />
          </div>
          <p className="terms">
            By signing up, you agree with our &nbsp;
            <span
              className="redirect"
              onClick={() => window.open(ROUTES.TERMS_OF_USE, "_blank")}
            >
              Terms of Use
            </span>
          </p>
          <button type="submit" className="submit">
            {signUpState === SignUpState.SIGNING && <div className="loader" />}
            Sign Up
          </button>
          {validationMessage && (
            <span className="validation-message">{validationMessage}</span>
          )}
        </form>
      )}
      <div className="alternative">
        <div>
          Already have an account? &nbsp;
          <span className="redirect" onClick={() => navigate(ROUTES.SIGNIN)}>
            Sign In
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
