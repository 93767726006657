import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { Library } from "hedwigai";
import Toast from "../components/Toast";

enum Theme {
  DARK = "dark-mode",
  LIGHT = "light-mode",
}

enum LoadingStatus {
  loading,
  loaded,
}

enum MissionView {
  SHARE,
  DOWNLOAD,
  EDIT_TAG,
  TYPOGRAPHY,
  FORGOT_PASSWORD,
  DELETE,
  ADD_TO_BOARD,
  CREATE_AVATAR,
  SIGN_UP,
  INVITE_MEMBER,
  REQUEST_INTEGRATION,
  BRAND_MISSION,
  BRAND_LOGO_MISSION,
}

interface ThemeState {
  theme: Theme;
  toggle: () => void;
}

const useThemeStore = create<ThemeState>()((set) => ({
  theme: Theme.LIGHT,
  toggle: () =>
    set((state: ThemeState) => ({
      theme: state.theme == Theme.LIGHT ? Theme.DARK : Theme.LIGHT,
    })),
}));

interface Invoice {
  name: string;
  timestamp: string;
  credit: string;
  paid: string;
}
interface Member {
  name: string;
  logo: string;
  email: string;
  generation: string;
  gender: string;
  location: string;
}

interface Brand {
  brandId: string;
  mission: string;
}

interface Integration {
  name: string;
  connected: boolean;
  icon: string;
  lastSync: string;
}

export const logout = () => {
  // useWebSocketStore.getState().logout();
  useAccountStore.getState().reset();
};

interface AccountState {
  exp: number | undefined;
  email: string | undefined;
  token: string | undefined;
  memberName: string | undefined;
  memberLogo: string | undefined;
  memberAccessLevel: boolean;
  memberGeneration: string | undefined;
  memberGender: string | undefined;
  memberLocation: string | undefined;
  activeBrand: string | null;
  activeBrandData: {};
  brandNames: string[];
  brandData: {}[];
  memberList: Member[];
  invoiceList: Invoice[];
  integrationList: Integration[];
  workspaceName: string | undefined;
  workspaceLogo: string | undefined;
  workspacePlan: string | undefined;
  workspaceCredit: number | undefined;
  workspaceDebit: number | undefined;
  sign: (email: string, token: string) => void;
  setWorkspace: (
    name: string,
    logo: string,
    plan: string,
    credit: number,
  ) => void;
  setAccountCredit: (debit: number) => void;
  setAccountDebit: (debit: number) => void;
  setInvoiceList: (invoiceList: Invoice[]) => void;
  setIntegrationList: (integrationList: Integration[]) => void;
  setMemberList: (member: Member[]) => void;
  setActiveBrandData: (data: {}) => void;
  setBrandData: (data: {}[]) => void;
  setBrandNames: (names: string[]) => void;
  setMember: (
    name: string,
    logo: string,
    generation: string,
    gender: string,
    location: string,
    accessLevel: boolean,
    activeBrand: string | null,
  ) => void;
  setActiveBrand: (brand: string) => void;
  setMemberLogo: (logo: string) => void;
  setMemberName: (name: string) => void;
  setMemberGeneration: (generation: string) => void;
  setMemberGender: (gender: string) => void;
  setMemberLocation: (location: string) => void;
  reset: () => void;
}

const useAccountStore = create<AccountState>()(
  persist(
    (set, get) => ({
      email: undefined,
      token: undefined,
      exp: undefined,
      memberName: undefined,
      memberLogo: undefined,
      memberAccessLevel: false,
      memberLocation: undefined,
      memberGender: undefined,
      memberGeneration: undefined,
      memberList: [],
      activeBrand: null,
      integrationList: [],
      activeBrandData: {},
      brandNames: [],
      brandData: [],
      invoiceList: [],
      setMemberList: (memberList: Member[]) =>
        set((state: AccountState) => ({
          memberList: memberList,
        })),
      setInvoiceList: (invoiceList: Invoice[]) =>
        set((state: AccountState) => ({
          invoiceList: invoiceList,
        })),
      setActiveBrandData: (data: {}) =>
        set((state: AccountState) => ({
          activeBrandData: data,
        })),
      setBrandData: (data) =>
        set((state: AccountState) => ({
          brandData: data,
        })),
      setBrandNames: (names: string[]) =>
        set((state: AccountState) => ({
          brandNames: names,
        })),
      setIntegrationList: (integrationList: Integration[]) =>
        set((state: AccountState) => ({
          integrationList: integrationList,
        })),
      workspaceName: undefined,
      workspaceLogo: undefined,
      workspacePlan: undefined,
      workspaceCredit: undefined,
      workspaceDebit: undefined,
      setWorkspace: (
        name: string,
        logo: string,
        plan: string,
        credit: number,
      ) =>
        set((state: AccountState) => ({
          workspaceName: name,
          workspaceLogo: logo,
          workspacePlan: plan,
          workspaceCredit: credit,
        })),
      setAccountCredit: (credit: number) =>
        set((state: AccountState) => ({ workspaceCredit: credit })),
      setAccountDebit: (debit: number) =>
        set((state: AccountState) => ({ workspaceDebit: debit })),
      sign: (email: string, token: string) =>
        set((state: AccountState) => ({
          email: email,
          token: token,
          exp: Date.now() + 10740000,
        })),
      setActiveBrand: (brand: string) =>
        set((state: AccountState) => ({
          activeBrand: brand,
        })),
      setMember: (
        name: string,
        logo: string,
        generation: string,
        gender: string,
        location: string,
        accessLevel: boolean,
        activeBrand: string | null,
      ) =>
        set((state: AccountState) => ({
          memberName: name,
          memberLogo: logo,
          memberGeneration: generation,
          memberGender: gender,
          memberLocation: location,
          memberAccessLevel: accessLevel,
          activeBrand: activeBrand,
        })),
      setMemberName: (name: string) =>
        set((state: AccountState) => ({
          memberName: name,
        })),
      setMemberLogo: (logo: string) =>
        set((state: AccountState) => ({
          memberLogo: logo,
        })),
      setMemberGeneration: (generation: string) =>
        set((state: AccountState) => ({
          memberGeneration: generation,
        })),
      setMemberGender: (gender: string) =>
        set((state: AccountState) => ({
          memberGender: gender,
        })),
      setMemberLocation: (location: string) =>
        set((state: AccountState) => ({
          memberLocation: location,
        })),
      reset: () => {
        set((state: AccountState) => ({
          exp: undefined,
          email: undefined,
          token: undefined,
          memberName: undefined,
          memberLogo: undefined,
          memberAccessLevel: false,
          memberGeneration: undefined,
          memberGender: undefined,
          memberLocation: undefined,
          memberList: [],
          integrationList: [],
          workspaceName: undefined,
          workspaceLogo: undefined,
          workspacePlan: undefined,
        }));
      },
    }),
    {
      name: "account-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

interface BrandState {
  brandId: string | undefined;
  mission: string | undefined;
  brandLogo: string | undefined;
  lockupValue: string | undefined;
  lockupSpace: string | undefined;
  clearSpace: string | undefined;
  setBrandLogo: (logo: string) => void;
  setLockupValue: (value: string) => void;
  setLockupSpace: (space: string) => void;
  setClearSpace: (space: string) => void;
  setBrandMission: (mission: string) => void;
  load: (brandId: string, library: Library) => void;
  clean: () => void;
}

const useBrandStore = create<BrandState>()((set, get) => ({
  brandId: "main",
  mission: undefined,
  brandLogo: undefined,
  lockupValue: undefined,
  lockupSpace: undefined,
  clearSpace: undefined,
  setBrandLogo: (logo: string) => {
    set((state: BrandState) => ({
      brandLogo: logo,
    }));
  },
  setLockupValue: (value: string) => {
    set((state: BrandState) => ({
      lockupValue: value,
    }));
  },
  setLockupSpace: (space: string) => {
    set((state: BrandState) => ({
      lockupSpace: space,
    }));
  },
  setClearSpace: (space: string) => {
    set((state: BrandState) => ({
      clearSpace: space,
    }));
  },
  setBrandMission: (mission: string) => {
    set((state: BrandState) => ({
      mission: mission,
    }));
  },
  load: (brandId: string, library: Library) => {
    library.getBrandMission(brandId).then((response: any) => {
      Toast(`Brand:${brandId} loaded!`);
      set((state: BrandState) => ({
        brandId: brandId,
        mission: response.name,
      }));
    });
  },
  clean: () => {
    set((state: BrandState) => ({
      brandId: undefined,
      mission: undefined,
    }));
  },
}));

interface PrimaryMemoryState {
  searchQuery: string;
  searchElementQuery: string;
  missionView: MissionView | undefined;
  bannerTypography: string | undefined;
  setBannerTypography: (item: string | undefined) => void;
  setMissionView: (item: MissionView | undefined) => void;
  setSearchQuery: (prompt: string) => void;
  setSearchElementQuery: (prompt: string) => void;
  clean: () => void;
  cleanElements: () => void;
}

const usePrimaryMemoryStore = create<PrimaryMemoryState>()((set, get) => ({
  searchQuery: "/",
  searchElementQuery: "brand",
  focusAsset: undefined,
  bannerTypography: undefined,
  setBannerTypography: (item: string | undefined) => {
    set((state: PrimaryMemoryState) => ({
      bannerTypography: item,
    }));
  },
  missionView: undefined,
  setMissionView: (item: MissionView | undefined) => {
    set((state: PrimaryMemoryState) => ({
      missionView: item,
    }));
  },
  setSearchQuery: (prompt: string) => {
    set((state: PrimaryMemoryState) => ({
      searchQuery: prompt,
    }));
  },
  setSearchElementQuery: (prompt: string) => {
    set((state: PrimaryMemoryState) => ({
      searchElementQuery: prompt,
    }));
  },
  clean: () => {
    set((state: PrimaryMemoryState) => ({ assets: [], searchQuery: "/" }));
  },
  cleanElements: () => {
    set((state: PrimaryMemoryState) => ({
      elements: [],
      searchElementQuery: "/",
    }));
  },
}));

interface BoardMemoryState {
  boardName: string;
  boardAssets: any[];
  setBoardName: (name: string) => void;
  push: (f: any) => void;
  clean: () => void;
}

const useBoardMemoryStore = create<BoardMemoryState>()((set, get) => ({
  boardName: "The eb5 Creatives",
  boardAssets: [],
  setBoardName: (name: string) => {
    set((state: BoardMemoryState) => ({
      boardName: name,
    }));
  },
  push: (item: any) => {
    set((state: BoardMemoryState) => ({
      boardAssets: [item, ...state.boardAssets],
    }));
  },
  clean: () => {
    set((state: BoardMemoryState) => ({ boardAssets: [], boardName: "" }));
  },
}));

interface SidebarMemoryState {
  isBarOpen: boolean;
  setIsBarOpen: (toggle: boolean) => void;
}

const useSidebarMemoryStore = create<SidebarMemoryState>((set, get) => ({
  isBarOpen: true,
  setIsBarOpen: (toggle: boolean) => {
    set((state: SidebarMemoryState) => ({
      isBarOpen: toggle,
    }));
  },
}));

export {
  useThemeStore,
  useAccountStore,
  Theme,
  LoadingStatus,
  MissionView,
  usePrimaryMemoryStore,
  useBoardMemoryStore,
  useSidebarMemoryStore,
  useBrandStore,
};

export type {
  ThemeState,
  AccountState,
  Member,
  Invoice,
  Integration,
  PrimaryMemoryState,
  BoardMemoryState,
};
