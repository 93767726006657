import React, { Suspense, useEffect } from "react";
import { createRoot } from "react-dom/client";
import "../css/app.scss";
import "react-loading-skeleton/dist/skeleton.css";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Theme, useAccountStore, useThemeStore } from "./lib/state";
import { MainUI } from "./Layouts/Main";
import useLibrary from "./lib/useLibrary";
import PrivateRoute from "./Layouts/PrivateRoute";
import { ROUTES } from "./lib/Route";
import { WebSocketProvider } from "./Layouts/SocketContext";
import ShareUI from "./Layouts/Share";
import { Toaster } from "react-hot-toast";
import SignIn from "./components/Auth/Signin";
import SignUp from "./components/Auth/Signup";
import BookDemoUI from "./components/Auth/BookDemo";

const ImageUI = React.lazy(() => import("./Layouts/Image"));
const Workspace = React.lazy(
  () => import("./components/ImageStudio/Workspace"),
);

const App = () => {
  const library = useLibrary();
  const token = useAccountStore((state) => state.token);
  const theme = useThemeStore((state) => state.theme);

  return (
    <div
      className={`app ${
        theme === Theme.LIGHT ? "theme--light" : "theme--dark"
      }`}
    >
      {/* <WebSocketProvider authToken={token}> */}
      <Toaster
        position={window.innerWidth < 640 ? "bottom-center" : "top-center"}
        reverseOrder
        gutter={20}
      />
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path={ROUTES.MAIN}>
              <Route index element={<MainUI library={library} />} />
              <Route
                path={ROUTES.SIGNIN}
                element={<SignIn library={library} />}
              />
              <Route
                path={ROUTES.BOOK_DEMO}
                element={<BookDemoUI library={library} />}
              />
              <Route
                path={ROUTES.SIGNUP}
                element={<SignUp library={library} />}
              />
              <Route
                path={ROUTES.ACTIVATE}
                element={<MainUI library={library} />}
              />
              <Route
                path={ROUTES.BILLING_CREDIT_CHECKOUT}
                element={<MainUI library={library} />}
              />
              <Route
                path={ROUTES.BILLING_SUBSCRIPTION_CHECKOUT}
                element={<MainUI library={library} />}
              />
              <Route
                path={ROUTES.INVITE}
                element={<MainUI library={library} />}
              />
              <Route
                path={ROUTES.MAGIC_LINK}
                element={<MainUI library={library} />}
              />
              <Route
                path={ROUTES.SHARE}
                element={<ShareUI library={library} />}
              />
              <Route element={<PrivateRoute library={library} />}>
                <Route
                  path={ROUTES.STUDIO}
                  element={<ImageUI library={library} />}
                />
                <Route
                  path={ROUTES.STUDIO_PLAYGROUND}
                  element={<Workspace library={library} />}
                />
              </Route>
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      {/* </WebSocketProvider> */}
    </div>
  );
};

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
