import React, { useEffect } from "react";
import Asset from "../../Asset";
import { Library } from "hedwigai";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../lib/Route";
import toast from "react-hot-toast";
import {
  initializeSignIn,
  validateSignInForm,
  ValidationMessages,
} from "./utils";
import { isValidEmail } from "../../lib/text";
import { useAccountStore } from "../../lib/state";

interface SignInProps {
  library: Library;
}

enum ForgotPasswordState {
  OPEN,
  SENT,
}

const SignIn: React.FC<SignInProps> = ({ library }) => {
  const navigate = useNavigate();
  const token = useAccountStore((state) => state.token);
  const [signing, setSigning] = React.useState<boolean>(false);
  const [validationMessage, setValidationMessage] = React.useState<
    ValidationMessages | undefined
  >(undefined);
  const [forgotPassword, setForgotPassword] = React.useState<
    ForgotPasswordState | undefined
  >(undefined);

  useEffect(() => {
    if (token) {
      navigate(ROUTES.STUDIO);
    }
  }, [token]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const username = e.currentTarget["username"].value;

    if (forgotPassword !== undefined) {
      handleForgotPassword(username);
      return;
    }

    const password = e.currentTarget["password"].value;

    if (validateSignInForm(username, password, setValidationMessage)) {
      setSigning(true);
      library
        .signIn(username, password)
        .then((success: any) => {
          setSigning(false);
          if (success === true) {
            initializeSignIn(library, username);
            navigate(ROUTES.STUDIO);
            toast.success("Sign in successful!");
          } else {
            setValidationMessage(ValidationMessages.INVALID_CREDENTIALS);
          }
        })
        .catch((e) => {
          setSigning(false);
          toast.error("Sign In failed");
          toast("Please try again", { icon: "ℹ️" });
          console.log("Error signing in", e);
        });
    }
  };

  const handleForgotPassword = (username: string) => {
    if (!username) {
      setValidationMessage(ValidationMessages.EMAIL_REQUIRED);
      return;
    }
    if (!isValidEmail(username)) {
      setValidationMessage(ValidationMessages.INVALID_EMAIL);
      return;
    }

    setValidationMessage(undefined);
    setSigning(true);
    library
      .forgotPassword(username)
      .then((success: any) => {
        setSigning(false);
        if (success) {
          setForgotPassword(ForgotPasswordState.SENT);
          toast.success("Magic link sent to your email!", { duration: 5000 });
        } else {
          setValidationMessage(ValidationMessages.INVALID_EMAIL);
        }
      })
      .catch((e) => {
        setSigning(false);
        toast.error("Error sending magic link");
        toast("Please try again", { icon: "ℹ️" });
        console.log("Error sending magic link", e);
      });
  };

  return (
    <div className="auth-page">
      <div className="header">
        <img
          src={Asset.LOGO_IMAGE}
          alt="logo-image"
          className="logo-image"
          onClick={() => navigate(ROUTES.MAIN)}
        />
        <h1>
          {forgotPassword === undefined
            ? "Login to hedwigAI"
            : "Change Password"}
        </h1>
      </div>
      <form onSubmit={handleFormSubmit}>
        <div className="section">
          <label htmlFor="username">Email *</label>
          <input
            type="text"
            id="username"
            name="username"
            autoCapitalize="none"
          />
        </div>
        {forgotPassword === undefined && (
          <div className="section">
            <label htmlFor="password">Password *</label>
            <input type="password" id="password" name="password" />
          </div>
        )}
        <button type="submit" className="submit">
          {signing && <div className="loader"></div>}
          {forgotPassword === undefined ? "Sign In" : "Send Magic Link"}
        </button>
        {validationMessage && (
          <span className="validation-message">{validationMessage}</span>
        )}
      </form>
      <div className="alternative">
        {forgotPassword === undefined ? (
          <span
            className="redirect"
            onClick={() => setForgotPassword(ForgotPasswordState.OPEN)}
          >
            Forgot your password?
          </span>
        ) : (
          <span
            className="redirect"
            onClick={() => setForgotPassword(undefined)}
          >
            Back to Sign In
          </span>
        )}

        <p>
          Don't have a hedwig account? &nbsp;
          <span className="redirect" onClick={() => navigate(ROUTES.BOOK_DEMO)}>
            Book a Demo
          </span>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
