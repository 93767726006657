import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Asset from "../Asset";
import { ROUTES } from "../lib/Route";
import { ThemeUI } from "../components/theme-toggle";
import { useAccountStore } from "../lib/state";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const navigate = useNavigate();
  const [toolsMenu, setToolsMenu] = React.useState<boolean>(false);
  const [burgerMenu, setBurgerMenu] = React.useState<boolean>(false);
  const token = useAccountStore((state) => state.token);

  useEffect(() => setToolsMenu(false), []);
  useEffect(() => setBurgerMenu(false), []);

  return (
    <div className="bar">
      <div className="brand">
        <img
          src={Asset.LOGO_IMAGE}
          style={{ width: "2.5rem" }}
          alt="hedwigAI"
          // onClick={() => navigate(ROUTES.SIGNIN)}
        />
        <p className="name" onClick={() => navigate(ROUTES.MAIN)}>
          hedwigAI
        </p>
        <p className="version-tag">beta</p>
      </div>
      <div className="user">
        <div
          className="cta"
          onClick={() => navigate(token ? ROUTES.STUDIO : ROUTES.SIGNIN)}
        >
          {token !== undefined ? "Dashboard" : "Sign In"}
        </div>
        <div className="button" onClick={() => navigate(ROUTES.BOOK_DEMO)}>
          Get a Demo
        </div>
      </div>
    </div>
  );
};

export { Header };
