import React from "react";
import Asset from "../Asset";
import { ROUTES } from "../lib/Route";

interface FooterProps {}

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  return (
    <div className="bottom-bar">
      <div className="footer">
        <a href={ROUTES.BOOK_DEMO} target="_blank">
          For Enterprise
        </a>
        <div className="social">
          <a href="https://discord.gg/kH6FvhVc" target="_blank">
            <span className="icon discord-icon"></span>
          </a>
          <a href="https://in.linkedin.com/company/hedwigai" target="_blank">
            <span className="icon linkedin-icon"></span>
          </a>
          <a href="https://www.youtube.com/@hedwig-ai" target="_blank">
            <span className="icon youtube-icon"></span>
          </a>
          <a href="https://twitter.com/hedwigaicom" target="_blank">
            <span className="icon x-icon"></span>
          </a>
        </div>
      </div>
    </div>
  );
};

export { Footer };
