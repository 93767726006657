import { Library, GenerateStyle, AspectRatio } from "hedwigai";

export interface AssetResolution {
  width: number;
  height: number;
}

// Function to convert Blob to Data URL
export const blobToDataURL = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export function fileToBlob(fileInput: HTMLInputElement): Promise<Blob> {
  return new Promise((resolve, reject) => {
    if (!fileInput || !fileInput.files || fileInput.files.length === 0) {
      reject(new Error("No file selected"));
      return;
    }

    const file = fileInput.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(base64ToBlob(reader.result));
      } else {
        reject(new Error("Failed to read file"));
      }
    };

    reader.onerror = () => {
      reject(new Error("Failed to read file"));
    };

    reader.readAsDataURL(file);
  });
}

export async function convertImageToBase64(imageUrl: string): Promise<string> {
  try {
    const response = await fetch(imageUrl, {
      mode: "cors",
      method: "GET",
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    if (!response.body) {
      throw new Error("Response body is null");
    }
    const base64 = await streamToBase64(response.body);
    return `data:image/png;base64,${base64}`;
  } catch (error) {
    console.error("Error converting image to base64:", error);
    return "";
  }
}

export const streamToBase64 = async (
  stream: ReadableStream,
): Promise<string> => {
  const reader = stream.getReader();
  const chunks: Uint8Array[] = [];
  let done = false;

  while (!done) {
    const { done: readerDone, value } = await reader.read();
    if (readerDone) {
      done = true;
    } else {
      chunks.push(value);
    }
  }

  const buffer = new Uint8Array(
    chunks.reduce((acc, chunk) => acc + chunk.length, 0),
  );
  let offset = 0;
  for (const chunk of chunks) {
    buffer.set(chunk, offset);
    offset += chunk.length;
  }

  let binary = "";
  buffer.forEach((byte) => {
    binary += String.fromCharCode(byte);
  });

  return window.btoa(binary);
};

export function base64ToBlob(base64: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    try {
      const matches = base64.match(/^data:(.*?);base64,/);
      if (!matches || matches.length !== 2) {
        throw new Error("Invalid base64 string");
      }
      const contentType = matches[1];

      const base64Data = base64.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: contentType });
      resolve(blob);
    } catch (error) {
      reject(error);
    }
  });
}

export async function imageUrlToBlob(imageUrl: string): Promise<Blob> {
  const response = await fetch(imageUrl);
  if (!response.ok) {
    throw new Error(`Failed to fetch image: ${response.statusText}`);
  }
  const imageBlob = await response.blob();
  return imageBlob;
}

export function getImageDimensions(
  base64: string,
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve({ width: image.width, height: image.height });
    };
    image.onerror = (error) => {
      reject(new Error("Failed to load image"));
    };
    image.src = base64;
  });
}

export function downsampleBase64Image(
  base64Image: string,
  quality: number,
): Promise<string> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d")!;

      canvas.width = image.width;
      canvas.height = image.height;

      ctx.drawImage(image, 0, 0, image.width, image.height);
      const newDataUrl = canvas.toDataURL("image/jpeg", 0.05);
      resolve(newDataUrl);

      image.src = "";
      image.onload = null;
      image.onerror = null;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = 0;
      canvas.height = 0;
    };

    image.onerror = () => {
      console.log("Failed to load image for downsampling");
      image.src = "";
      image.onload = null;
      image.onerror = null;
    };
    if (base64Image === "") resolve("");
    image.src = base64Image;
  });
}

export const fetchImageAll = async (
  library: Library,
  fileIds: string[],
): Promise<string[]> => {
  const handleError = (error: unknown, context: string) => {
    if (error instanceof Error) {
      console.error(`Error ${context}: ${error.message}`);
    } else {
      console.error(`Unknown error ${context}: ${JSON.stringify(error)}`);
    }
  };

  try {
    const r = (await library.getFileUrl(fileIds)) as any;
    const res = r.response as any[];
    const base64Promises = res.map(async (item) => {
      try {
        return await convertImageToBase64(item.url);
      } catch (error) {
        handleError(error, "converting image to base64");
        return null;
      }
    });

    const base64s = await Promise.all(base64Promises);
    return base64s.filter((base64) => base64 !== null) as string[];
  } catch (error) {
    handleError(error, "fetching file URLs");
    return [];
  }
};

export const fetchImage = async (library: Library, fileId: string) => {
  const res = (await library.getFileUrl(fileId)) as any;
  const imageBase64 = await convertImageToBase64(res.response[0].url);
  return imageBase64;
};
