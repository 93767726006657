import { Library } from "hedwigai";
import { create } from "zustand";

export interface Options {
  [key: string]: string[];
}

export interface Structure {
  structure: string;
  option: Options;
}

interface KeywordSelectorState {
  promptGuide: Structure[];
  setPromptGuide: (structureList: Structure[]) => void;
}

const useKeywordSelectorStore = create<KeywordSelectorState>((set) => ({
  promptGuide: [],
  setPromptGuide: (structureList) => set({ promptGuide: structureList }),
}));

export default useKeywordSelectorStore;
