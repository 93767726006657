import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Asset from "../Asset";
import { ROUTES } from "../lib/Route";
import { Library } from "hedwigai";
import { Invoice, Member, useAccountStore } from "../lib/state";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { getCurrentLocation } from "../lib/location";
import toast from "react-hot-toast";

interface MainProps {
  library: Library;
}

enum ModalState {
  SIGN_IN,
  SIGN_UP,
}

const MainUI: React.FC<MainProps> = ({ library }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const sign = useAccountStore((state) => state.sign);
  const setMemberLocation = useAccountStore((state) => state.setMemberLocation);
  const setMember = useAccountStore((state) => state.setMember);
  const setMemberList = useAccountStore((state) => state.setMemberList);
  const setInvoiceList = useAccountStore((state) => state.setInvoiceList);

  useEffect(() => {
    console.log(`Location: ${location.pathname}`);
    if (location.pathname == ROUTES.ACTIVATE) {
      const searchParams = new URLSearchParams(location.search);
      toast("Please wait for a moment while we activate your account");
      library
        .activateMember(
          searchParams.get("e") as string,
          searchParams.get("s") as string,
        )
        .then((data: any) => {
          console.log(`Activation: ${data["email"]}`);
          const email = data["email"];
          toast(`Searching in the waitlist for ${email}`);
          setTimeout(() => {
            if (email) {
              toast(`Assembling the Platform for ${email}`);
              sign(email, library.getIdToken());
              library.getMember().then((item: any) => {
                const value = item[0];
                setMember(
                  value["name"] as string,
                  value["avatar"] as string,
                  value["generation"] as string,
                  value["gender"] as string,
                  value["location"] as string,
                  value["access_level"] as boolean,
                  value["active_brand"] as string | null,
                );
                navigate(ROUTES.STUDIO);
              });

              library.getMemberList().then((result: any) => {
                const memberList: Member[] = result.map((value: any) => {
                  return {
                    name: value["name"],
                    logo: value["avatar"],
                    email: value["email"],
                    generation: value["generation"],
                    gender: value["gender"],
                    location: value["location"],
                  } as Member;
                });
                setMemberList(memberList);
              });

              getCurrentLocation().then((value) => {
                if (typeof value == "undefined") return;
                setMemberLocation(value);
              });
            } else {
              toast("Email Verification Failed 😥");
            }
          }, 1000);
        })
        .catch((err) => {
          toast("Email Verification Failed 😥");
          console.log(`Activation Error: ${err}`);
        });
    }

    if (location.pathname == ROUTES.MAGIC_LINK) {
      console.log(`Magic Link: ${location.search}`);
      const searchParams = new URLSearchParams(location.search);
      library
        .magicLink(
          searchParams.get("e") as string,
          searchParams.get("s") as string,
        )
        .then((data: any) => {
          const response = data["response"];
          console.log(`MagicResponse: ${response}`);
          // library.setIdToken(response["idToken"]);
          const email = response["email"];
          setTimeout(() => {
            if (email) {
              toast(`Assembling the Platform for ${email}`);
              sign(email, library.getIdToken());
              library.getMember().then((item: any) => {
                const value = item[0];
                setMember(
                  value["name"] as string,
                  value["avatar"] as string,
                  value["generation"] as string,
                  value["gender"] as string,
                  value["location"] as string,
                  value["access_level"] as boolean,
                  value["active_brand"] as string | null,
                );
                navigate(ROUTES.STUDIO);
              });

              library.getMemberList().then((result: any) => {
                const memberList: Member[] = result.map((value: any) => {
                  return {
                    name: value["name"],
                    logo: value["avatar"],
                    email: value["email"],
                    generation: value["generation"],
                    gender: value["gender"],
                    location: value["location"],
                  } as Member;
                });
                setMemberList(memberList);
              });

              getCurrentLocation().then((value) => {
                if (typeof value == "undefined") return;
                setMemberLocation(value);
              });
            } else {
              toast("Email Verification Failed 😥");
            }
          }, 1000);
        });
    }

    if (location.pathname == ROUTES.BILLING_CREDIT_CHECKOUT) {
      const searchParams = new URLSearchParams(location.search);
      const checkoutId = searchParams.get("checkout_id") as string;
      const token = searchParams.get("token") as string;
      library.billAccountCreditCheckout(checkoutId, token).then((success) => {
        if (success) {
          library.getInvoiceList().then((item: any) => {
            const invoiceList: Invoice[] = item.map((value: any) => {
              return {
                name: value["name"],
                timestamp: value["timestamp"],
                credit: value["credit"],
                paid: value["paid"],
              } as Invoice;
            });
            setInvoiceList(invoiceList);
          });
          navigate(ROUTES.STUDIO);
          toast.success(`Thank you for your purchase!`);
        } else {
          navigate(ROUTES.STUDIO);
          toast.error(`Sorry your purchase was not completed 😞 !`);
        }
      });

      navigate(ROUTES.STUDIO);
    }

    if (location.pathname == ROUTES.BILLING_SUBSCRIPTION_CHECKOUT) {
      const searchParams = new URLSearchParams(location.search);
      const checkoutId = searchParams.get("checkout_id") as string;
      const token = searchParams.get("token") as string;
      library
        .billAccountSubscriptionCheckout(checkoutId, token)
        .then((success) => {
          if (success) {
            library.getInvoiceList().then((item: any) => {
              const invoiceList: Invoice[] = item.map((value: any) => {
                return {
                  name: value["name"],
                  timestamp: value["timestamp"],
                  credit: value["credit"],
                  paid: value["paid"],
                } as Invoice;
              });
              setInvoiceList(invoiceList);
            });
            navigate(ROUTES.STUDIO);
            toast(`Welcome ✨ Pro User!`);
          } else {
            navigate(ROUTES.STUDIO);
            toast.error(`Sorry your subscription was not completed 😞!`);
          }
        });

      navigate(ROUTES.STUDIO);
    }

    if (location.pathname == ROUTES.INVITE) {
      const searchParams = new URLSearchParams(location.search);
      const encodedEmail = searchParams.get("e") as string;
      const email = encodedEmail ? atob(encodedEmail) : "invalid@gmail.com";
      toast(`Welcome ${email}!`);
    }
  }, [location]);

  return (
    <>
      <Header />
      <div className="main">
        <div className="headline">
          <div className="text">
            <span className="start" style={{ "--i": "1" }}>
              The
            </span>
            <span className="start" style={{ "--i": "2" }}>
              Future
            </span>
            <span className="start" style={{ "--i": "3" }}>
              of
            </span>
            <span className="start" style={{ "--i": "4" }}>
              Shop
            </span>
            <span className="start" style={{ "--i": "5" }}>
              Design
            </span>
            <span
              className="focus"
              style={{ "--i": "6" }}
              onClick={() => navigate(ROUTES.BOOK_DEMO)}
            >
              is here
            </span>
          </div>
          <div className="cta" style={{ "--i": "4" }}>
            <div className="button" onClick={() => navigate(ROUTES.SIGNIN)}>
              <div className="label">Get Started</div>
            </div>
            <div className="button" onClick={() => navigate(ROUTES.BOOK_DEMO)}>
              Get a Demo
            </div>
          </div>
        </div>
        <div className="highlight">
          <div className="feature" style={{ "--i": "4" }}>
            <div className="visual">
              <img
                id="static"
                src={Asset.CREATIVES_ICON}
                // onClick={() => toast.success("Coming Soon!")}
              ></img>
              <img
                id="motion"
                src={Asset.CREATIVES_MOTION_ICON}
                // onClick={() => redirect("https://tidycal.com/shashank")}
              ></img>
            </div>
            <div className="content">
              <h2>Creatives</h2>
            </div>
          </div>
          <div className="feature" style={{ "--i": "4" }}>
            <div className="visual">
              <img
                id="static"
                src={Asset.PHOTOGRAPHY_ICON}
                // onClick={() => navigate(ROUTES.MAIN)}
              ></img>
              <img
                id="motion"
                src={Asset.PHOTOGRAPHY_MOTION_ICON}
                // onClick={() => navigate(ROUTES.MAIN)}
              ></img>
            </div>
            <div className="content">
              <h2>Photography</h2>
            </div>
          </div>
          <div className="feature" style={{ "--i": "4" }}>
            <div className="visual">
              <img
                id="static"
                src={Asset.SHARE_ICON}
                onClick={() => navigate(ROUTES.STUDIO)}
              ></img>
              <img
                id="motion"
                src={Asset.SHARE_MOTION_ICON}
                onClick={() => navigate(ROUTES.STUDIO)}
              ></img>
            </div>
            <div className="content">
              <h2>Share with 🌏</h2>
            </div>
          </div>
        </div>
        <div className="watermark"></div>
      </div>
      <Footer />

      {/* {modalState === ModalState.SIGN_IN && <SignIn />} */}
      {/* <SignIn /> */}
    </>
  );
};

export { MainUI };
