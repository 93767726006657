import React, { useEffect } from "react";
import Asset from "../../Asset";
import { Library } from "hedwigai";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../lib/Route";
import toast from "react-hot-toast";
import { ValidationMessages } from "./utils";
import { get } from "lodash";

interface BookDemoProps {
  library: Library;
}

enum VerificationState {
  VALID,
  VERIFYING,
  VERIFIED,
}

function formatDateToLocalInput(date: Date, addDate: number = 0) {
  if (addDate) {
    date.setDate(date.getDate() + addDate);
  }
  const padZero = (num: number) => num.toString().padStart(2, "0");
  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1); // Months are zero-indexed
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

const BookDemoUI: React.FC<BookDemoProps> = ({ library }) => {
  const navigate = useNavigate();

  const [validationMessage, setValidationMessage] = React.useState<
    ValidationMessages | undefined
  >(undefined);
  const [verifying, setVerifying] = React.useState<boolean>(false);
  const [verificationState, setVerificationState] = React.useState<
    VerificationState | undefined
  >();
  const [email, setEmail] = React.useState<string>("");
  const [datetime, setDatetime] = React.useState<string>("");

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = e.currentTarget["email"].value;
    if (
      !email ||
      email.length === 0 ||
      !email.trim() ||
      email.indexOf("@") === -1
    ) {
      toast.error("Please enter your email");
      return;
    }
    const datetime = e.currentTarget["datetime-local"].value;
    if (!datetime) {
      toast.error("Please select a date and time");
      return;
    }
    setEmail(email);
    setDatetime(datetime);
    setVerificationState(VerificationState.VALID);
  };

  const [verificationSent, setVerificationSent] =
    React.useState<boolean>(false);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (verificationState === VerificationState.VALID && !verificationSent) {
      sendVerification();
    }
  }, [verificationState]);

  const sendVerification = () => {
    if (verificationState != VerificationState.VALID || verificationSent)
      return;
    setVerificationSent(true);
    library
      .scheduleDemo(email, datetime, timezone)
      .then((r: any) => {
        toast.success(`Verification sent on ${email}`);
      })
      .catch((e) => {
        setVerificationSent(false);
        toast.error("Failed to send verification code");
        toast("Please try again", { icon: "ℹ️" });
        console.log("Error sending verification code", e);
      });
  };

  const verifyBooking = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const secretCode = inputCodeRefs.current.map((ref) => ref.value).join("");
    console.log("Verification code", secretCode);
    setVerificationState(VerificationState.VERIFYING);

    console.log(
      "Timezone",
      timezone,
      "Email",
      email,
      "Datetime",
      datetime,
      "Code",
      secretCode,
    );
    // TODO
    library
      .bookDemo(email, secretCode)
      .then((r: any) => {
        setVerificationState(VerificationState.VERIFIED);
        toast.success("Booking confirmed!");
        setTimeout(() => navigate(ROUTES.MAIN), 1500);
      })
      .catch((e) => {
        setVerificationState(VerificationState.VALID);
        toast.error("Verification failed");
        toast("Please try again", { icon: "ℹ️" });
        console.log("Error verifying booking", e);
      });
  };

  const inputCodeRefs = React.useRef<HTMLInputElement[]>([]);

  const getDefaultDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 2);
    tomorrow.setHours(11, 30, 0, 0); // Set time to 11:30 AM

    // Format the date to "YYYY-MM-DDTHH:MM"
    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, "0");
    const day = String(tomorrow.getDate()).padStart(2, "0");
    const hours = String(tomorrow.getHours()).padStart(2, "0");
    const minutes = String(tomorrow.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  return (
    <div className="auth-page">
      <div className="header">
        <img
          src={Asset.LOGO_IMAGE}
          alt="logo-image"
          className="logo-image"
          onClick={() => navigate(ROUTES.MAIN)}
        />
        <h1>Schedule a Demo</h1>
      </div>
      {typeof verificationState != "undefined" && verificationSent ? (
        <>
          <form onSubmit={verifyBooking}>
            <div className="section">
              <div className="code-inputs">
                {Array(4)
                  .fill("")
                  .map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      className="code-input"
                      ref={(el) =>
                        el != null ? (inputCodeRefs.current[index] = el) : null
                      } // Save reference to the input field
                      onChange={(e) =>
                        e.target.value.length === 1 &&
                        index < inputCodeRefs.current.length - 1
                          ? inputCodeRefs.current[index + 1].focus()
                          : null
                      }
                      onKeyDown={(e) =>
                        e.key === "Backspace" &&
                        e.target.value.length === 0 &&
                        index > 0
                          ? inputCodeRefs.current[index - 1].focus()
                          : null
                      }
                    />
                  ))}
              </div>
            </div>
            <button type="submit" className="submit">
              {verificationState === VerificationState.VERIFYING && (
                <div className="loader"></div>
              )}
              Verify
            </button>
          </form>
          <div className="auth-message">
            <h3>Almost there!</h3>
            <p>
              Please check your email for a verification code. Didn't receive
              it? Be sure to check your spam or junk folder.
            </p>
            <span className="redirect" onClick={() => sendVerification()}>
              Resend
            </span>
            {/* TODO Add code input UI */}
            <br />
            <p>
              <span className="redirect" onClick={() => navigate(ROUTES.MAIN)}>
                Back to home
              </span>
            </p>
          </div>
        </>
      ) : (
        <form onSubmit={handleFormSubmit}>
          <div className="section">
            <label htmlFor="email">Email *</label>
            <input type="text" id="email" name="email" />
          </div>
          <div className="section">
            <label htmlFor="datetime">Date & Time *</label>
            <input
              aria-label="Date and time"
              type="datetime-local"
              id="datetime-local"
              defaultValue={getDefaultDate()}
              min={formatDateToLocalInput(new Date(), 1)}
              max={formatDateToLocalInput(new Date(), 14)}
            />
          </div>
          <button type="submit" className="submit">
            Book a Demo
          </button>
          {validationMessage && (
            <span className="validation-message">{validationMessage}</span>
          )}
        </form>
      )}
      <div className="alternative">
        <div>
          Already have an account? &nbsp;
          <span className="redirect" onClick={() => navigate(ROUTES.SIGNIN)}>
            Sign In
          </span>
        </div>
      </div>
    </div>
  );
};

export default BookDemoUI;
