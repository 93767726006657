import { Library } from "hedwigai";
import { Member, useAccountStore } from "../../lib/state";
import { getCurrentLocation } from "../../lib/location";
import { isValidEmail } from "../../lib/text";
import useKeywordSelectorStore from "../ImageStudio/components/KeywordSelector/state";

export enum ValidationMessages {
  INVALID_CREDENTIALS = "Invalid credentials",
  EMAIL_REQUIRED = "Email is required",
  PASSWORD_REQUIRED = "Password is required",
  INVALID_EMAIL = "Invalid email",
  PASSWORD_LENGTH = "Password must be at least 6 characters",
}

export const validateSignInForm = (
  username: string,
  password: string,
  setValidationMessage: React.Dispatch<
    React.SetStateAction<ValidationMessages | undefined>
  >,
) => {
  if (!username) {
    setValidationMessage(ValidationMessages.EMAIL_REQUIRED);
    return false;
  }
  if (!password) {
    setValidationMessage(ValidationMessages.PASSWORD_REQUIRED);
    return false;
  }
  if (!isValidEmail(username)) {
    setValidationMessage(ValidationMessages.INVALID_EMAIL);
    return false;
  }
  setValidationMessage(undefined);
  return true;
};

export const validateSignUpForm = (
  username: string,
  password: string,
  setValidationMessage: React.Dispatch<
    React.SetStateAction<ValidationMessages | undefined>
  >,
) => {
  if (!username) {
    setValidationMessage(ValidationMessages.EMAIL_REQUIRED);
    return false;
  }
  if (!password) {
    setValidationMessage(ValidationMessages.PASSWORD_REQUIRED);
    return false;
  }
  if (!isValidEmail(username)) {
    setValidationMessage(ValidationMessages.INVALID_EMAIL);
    return false;
  }
  if (password.length < 6) {
    setValidationMessage(ValidationMessages.PASSWORD_LENGTH);
    return false;
  }
  setValidationMessage(undefined);
  return true;
};

export const initializeSignIn = (library: Library, username: string) => {
  const accountStore = useAccountStore.getState();
  const keywordSelectorStore = useKeywordSelectorStore.getState();

  accountStore.sign(username, library.getIdToken());
  library
    .getMember()
    .then((item: any) => {
      const value = item[0];
      accountStore.setMember(
        value["name"] as string,
        value["avatar"] as string,
        value["generation"] as string,
        value["gender"] as string,
        value["location"] as string,
        value["access_level"] as boolean,
        value["active_brand"] as string | null,
      );
    })
    .catch((e) => {
      console.log("Error getting member data", e);
    });

  library
    .getMemberList()
    .then((item: any) => {
      const memberList: Member[] = item.map((value: any) => {
        return {
          name: value["name"],
          logo: value["avatar"],
          email: value["email"],
          generation: value["generation"],
          gender: value["gender"],
          location: value["location"],
          accessLevel: value["access_level"],
        } as Member;
      });
      accountStore.setMemberList(memberList);
    })
    .catch((e) => {
      console.log("Error getting member list", e);
    });

  library
    .listBrandStory()
    .then((r: any) => {
      if (r.response.length === 0) {
        return;
      }
      let brand_names: any[] = [];
      let brand_data: any = {};
      for (const obj of r.response) {
        brand_data[obj["brand_name"]] = obj;
      }
      accountStore.setBrandNames(brand_names);
      accountStore.setBrandData(brand_data);
      library.getActiveBrand().then((item: any) => {
        const result = item["response"]["brand_name"];
        accountStore.setActiveBrand(result);
        accountStore.setActiveBrandData(brand_data[result]);
      });
    })
    .catch((e: any) => {
      console.log("Error getting brand data", e);
    });

  library
    .getPromptGuide()
    .then((r: any) => {
      const promptGuide = r.response;
      keywordSelectorStore.setPromptGuide(promptGuide);
    })
    .catch((e: any) => {
      console.log("Error getting prompt guide", e);
    });

  getCurrentLocation().then((value) => {
    if (typeof value == "undefined") return;
    accountStore.setMemberLocation(value);
  });
};
